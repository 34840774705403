import * as React from 'react';

import { Text, BlogImage, BlogInfographics, BlogLink } from '../../components';

import { PageNew, SectionBlog } from '..';

import infographic from '../../images/educational-corner/blog6/infografika.png';
import googleMeetShareScreen from '../../images/educational-corner/blog6/Google-meet-dijeljenje-zaslona.png';
import shareScreen from '../../images/educational-corner/blog6/Podjela-zaslona-za-uspjesnu-terapiju.png';
import distanceSetup from '../../images/educational-corner/blog6/Priprema-za-rad-na-daljinu.png';
import distanceTherapyWithKokolingo from '../../images/educational-corner/blog6/Terapija-na-daljinu-uz-Kokolingo.png';

const DistanceSpeechTherapy = () => {
  return (
    <PageNew
      title="KOKOLINGO – Logopedska Terapija na Daljinu"
      description="Budući da je KOKOLINGO digitalni alat, idealan je primjer
      logopedskog materijala koji se može koristiti u terapiji na daljinu."
      headline="Logo terapija na daljinu"
    >
      <Text as="p">
        Terapija na daljinu poznata je u svijetu pod imenom teleterapija (eng.{' '}
        <i>teletherapy</i>). Ona omogućava terapijski rad, primjerice logopedski
        ili psihološki, putem videokonferencijskih alata.
        <b>
          {' '}
          Ovaj oblik terapije sličan je radu uživo, a razlikuje se u tome što
          terapeut i klijent nisu u istoj prostoriji, odnosno na istome mjestu.{' '}
        </b>
        Njome se omogućava terapija onim osobama koje su kilometrima udaljene od
        ustanove u kojoj se pruža terapijska usluga. Budući da je većina takvih
        ustanova u većim gradovima, terapijom na daljinu najčešće se pruža
        podrška onim osobama koje žive u ruralnim područjima. Ovaj način
        terapije nudi fleksibilnost u radu jer omogućava svladavanje prepreka
        udaljenosti i nedostupnosti stručnjaka. U trenutačnoj situaciji, zbog
        pandemije koronavirusa, otežana je ili potpuno onemogućena terapija
        uživo. Zbog toga je i važnost terapije na daljinu postala još veća.
      </Text>
      <Text as="h2">Videokonferencijski alati</Text>
      <Text as="p">
        Terapija na daljinu zahtijeva korištenje videokonferencijskih alata Za
        korištenje ovim alatima, osobe koje sudjeluju u videopozivu moraju imati
        stabilnu internetsku vezu i uređaj kojim će uspostaviti poziv -
        primjerice laptop ili tablet. Za kvalitetniju sliku i zvuk potrebno je
        koristiti vanjsku web kameru i vanjski mikrofon. Većina
        videokonferencijskih alata funkcionira putem pretplate, ali gotovo svaki
        od njih ima i besplatnu opciju. Taj besplatni paket uglavnom ima
        vremensko ograničenje poziva, primjerice 40 minuta ili sat vremena, što
        je dovoljno, s obzirom na to da se to vrijeme otprilike poklapa s
        vremenom trajanja jedne logopedske terapije uživo. Neki od tih alata su
        <a
          href="https://meet.google.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          <b>Google Meet</b>
        </a>
        ,{' '}
        <a href="https://zoom.us/" target="_blank" rel="noopener noreferrer">
          {' '}
          <b>Zoom</b>
        </a>
        ,
        <a
          href="https://www.skype.com/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          <b>Skype</b>
        </a>{' '}
        ili videokonferencijski alati kreirani upravo u svrhu teleterapije, kao
        što je
        <a href="https://doxy.me/" target="_blank" rel="noopener noreferrer">
          {' '}
          <b>doxy.me</b>
        </a>
        . Svi ovi alati funkcioniraju na sličan način, a najveće su razlike u
        izgledu korisničkog sučelja i u funkcionalnostima besplatnog paketa.
      </Text>
      <Text as="h2">Materjiali za rad na daljinu</Text>
      <Text as="p">
        <b>
          Tijekom terapije na daljinu, logoped se može koristiti svim
          materijalima koje ima u digitalnom obliku.{' '}
        </b>
        Većina njih bit će u Word i PDF obliku. Ti se materijali mogu
        upotrijebiti u terapiji na daljinu jer se mogu podijeliti na ekranu.
        Tako roditelj i dijete mogu vidjeti i čuti sve ono što logoped vidi na
        svom ekranu te zajednički prolaziti kroz sve aktivnosti. U ovakvom
        načinu rada logoped bi, u većini slučajeva, trebao dijeliti svoj ekran,
        a ne obrnuto, tako da ima kontrolu nad tijekom obavljanja različitih
        aktivnosti. Osim digitalnih sadržaja, logoped može upotrebljavati i
        konkrete, primjerice plišane igračke. Njih treba približiti kameri i
        rabiti ih tako da sve što se s njima radi bude vidljivo na ekranu.
      </Text>
      <BlogImage
        imgSrc={distanceTherapyWithKokolingo}
        imgAlt="Terapija na daljinu uz Kokolingo"
        imgTitle="Terapija na daljinu uz Kokolingo"
      />
      <Text as="h2">Priprema za rad na daljinu</Text>
      <Text as="p">
        <b>
          U ovoj vrsti terapije, ali i u uobičajenoj terapiji, potrebno je imati
          dobru suradnju s roditeljima i njegovati partnerski odnos.{' '}
        </b>
        Prije samog početka treba upoznati roditelje s ovakvim načinom rada i
        time što sve on podrazumijeva, posebice s tehničkim detaljima
        videopoziva. Također, potrebno je dogovoriti i vrijeme kada će se
        terapija održavati. Tijekom videopoziva, važno je i gdje će biti
        roditelji. Ako se radi o djeci predškolskog uzrasta, poželjno je da
        roditelj sjedi, a dijete bude u krilu. Tako roditelji mogu usmjeravati
        dijete, odnosno činiti ono što bi logoped činio da provodi terapiju
        uživo. Kod djeteta školske dobi dovoljno je da roditelj bude u blizini,
        ako dođe do tehničkih teškoća.
      </Text>
      <BlogImage
        imgSrc={distanceSetup}
        imgAlt="Priprema za rad na daljinu"
        imgTitle="Priprema za rad na daljinu"
      />
      <Text as="h2">Motivatori u terapiji na daljinu</Text>
      <Text as="p">
        Među najvećim preprekama koje treba svladati tijekom terapije na daljinu
        svakako je održavanje djetetove koncentracije i motivacije za rad. Svako
        je dijete individualno te će neka djeca imati više koncentracije i
        motivacije za rad, a drugima će pažnja i koncentracija biti varijabilne.
        <b>
          {' '}
          Kao i u terapiji uživo, da ne bi došlo do zamora, važno je uvoditi
          zabavne aktivnosti između zadataka.{' '}
        </b>
        Ove aktivnosti mogu biti u obliku Youtube snimki, primjerice dječjih
        pjesmica ili prizora iz animiranih filmova. Također, moguće je igrati
        različite besplatne online igre. Naravno, i video snimke i igre logoped
        treba podijeliti na svom ekranu tako da kontrolira koliko će trajati
        pojedina aktivnost. Te zabavne aktivnosti, osim što služe kao
        motivatori, mogu se iskoristiti i za jačanje jezično-govornih vještina,
        primjerice postavljanjem pitanja, razgovorom ili prepričavanjem.
      </Text>
      <Text as="h2">Kokolingo digitalna vježbenica i google meet</Text>
      <Text as="p">
        Kokolingo je digitalna logopedska vježbenica namijenjena djeci s
        artikulacijskim poremećajima. Budući da je riječ o digitalnom alatu,
        idealan je primjer logopedskog materijala koji se može koristiti u
        terapiji na daljinu. S obzirom na to da velik broj djece predškolske,
        ali i osnovnoškolske dobi ima neki oblik artikulacijskih poremećaja,
        većina je te djece sada, tijekom pandemije koronavirusa, u situaciji da
        im je otežano ili onemogućeno pohađanje logopedske terapije. U
        trenutačnoj situaciji važnost terapije na daljinu postaje sve veća. Kako
        bi ovaj oblik terapije funkcionirao, koristeći se Chrome web
        preglednikom, na primjeru Kokolingo aplikacije i uz korištenje Google
        Meet softvera?
      </Text>
      <Text as="p">
        Najprije, logoped i roditelj trebali bi se upoznati s načinom rada
        <a
          href="https://apps.google.com/meet/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          <b>Google Meet softvera</b>
        </a>
        . Za njegovo korištenje potrebno je imati Google račun, odnosno Gmail
        račun. Nakon toga, treba dogovoriti vrijeme kada će se terapija
        održavati. Najbolje je da logoped u Google kalendaru zakaže sastanak
        tako da roditelj i logoped u svakom trenutku znaju kada će se videopoziv
        održati. Više o samom načinu funkcioniranja Google Meet softvera možete
        pročitati
        <a
          href="https://support.google.com/meet/answer/9302870?hl=hr&ref_topic=7192926"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          <b>ovdje</b>
        </a>
        . Prije samog sastanka, pet do deset minuta ranije, najbolje je
        provjeriti internetsku vezu te funkcioniraju li kamera i mikrofon.
        Tijekom prvih nekoliko minuta videopoziva uglavnom će biti riječi o tome
        kako dijete napreduje, rješava li zadatke za domaću zadaću, što je
        radilo taj dan itd. Važno je naglasiti da Google Meet dopušta sastanak u
        trajanju do jednog sata pa je potrebno obratiti pozornost na vrijeme.
      </Text>
      <Text as="h2">Dijeljenje zaslona i kvaliteta zvuka</Text>
      <Text as="p">
        Kada su obje strane spremne, logoped će podijeliti svoj zaslon. Više o
        tome kako to učiniti možete pročitati
        <a
          href="https://support.google.com/meet/answer/9308856?hl=hr&ref_topic=7290350"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          <b>ovdje</b>
        </a>
        . Najbolji način dijeljenja audio i videomaterijala u Google Meet
        softveru, preko kojeg će zvuk biti “najčišći”, jest da se prezentira kao
        Kartica A. U tom slučaju, da ne bi došlo do mikrofonije, logoped bi se
        tijekom videopoziva trebao koristiti slušalicama. Poželjno je da logoped
        unaprijed pripremi materijale kojima će se koristiti, primjerice da se
        prijavi u<BlogLink> Kokolingo vježbenicu </BlogLink>i aktivira onu
        aktivnost kojom će započeti vježbe. U drugom prozoru može otvoriti
        online igre ili YouTube videozapise kojima će se koristiti kao
        motivatorima. Online igre su raznolike i većina njih besplatna je za
        korištenje, primjerice
        <a
          href="https://www.highlightskids.com/games/my-first-hidden-pictures/dress-up-time"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>
            <b> Pronađi skrivene predmete</b>
          </i>
        </a>
        . YouTube video logoped odabrat će prema onome što dijete voli gledati,
        primjerice jednu epizodu Peppa Pig animirane serije.
      </Text>
      <BlogImage
        imgSrc={googleMeetShareScreen}
        imgAlt="Dijeljenje zaslona na Google Meet platformi"
        imgTitle="Dijeljenje zaslona na Google Meet platformi"
      />
      <Text as="h2">Nekoliko trikova za najbolje korisničko iskustvo</Text>
      <Text as="p">
        Kako bi terapija na daljinu bila uspješna, dok logoped dijeli svoj ekran
        mora vidjeti dijete i roditelja. Najbolje je Google Meet videopoziv
        otvoriti u jednom Chrome prozoru, a Kokolingo i sve ostale igre i
        videomaterijale pripremiti u drugom. Tako će logoped u jednom prozoru
        vidjeti dijete, a u drugom će prolaziti kroz pripremljene materijale. Za
        najbolje korisničko iskustvo poželjno je imati dva monitora za lakši
        rad. Moguće je i smanjiti oba Chrome prozora tako da stanu na jedan
        ekran, ako dva monitora nisu opcija. Jednom kada logoped počne dijeliti
        svoj ekran, njegova će se kamera ugasiti te je bitno ponovno je upaliti
        klikom na ikonu kamere. Važno je da dijete čuje, ali i vidi terapeuta,
        posebice ako je potrebno pokazati određeni položaj ili pokret
        artikulatora, primjerice jezika. Djetetova pažnja i koncentracija
        tijekom terapije varirat će pa će logoped izmjenjivati zadatke i
        motivatore, a roditelj će pomagati.
      </Text>
      <BlogImage
        imgSrc={shareScreen}
        imgAlt="Podjela zaslona za uspjesnu terapiju"
        imgTitle="Podjela zaslona"
      />
      <Text as="p">
        Da bi terapija bila uspješna, logoped i roditelj moraju surađivati.
        Terapija na daljinu zahtijeva razdoblje privikavanja i učenja - kako
        logopeda tako i roditelja, ali i djeteta. Zajednički rad i ustrajnost
        dovest će do pozitivnih pomaka kod djeteta, bilo da je riječ o terapiji
        uživo ili o terapiji na daljinu.Da bi terapija bila uspješna, logoped i
        roditelj moraju surađivati. Terapija na daljinu zahtijeva razdoblje
        privikavanja i učenja - kako logopeda tako i roditelja, ali i djeteta.
        Zajednički rad i ustrajnost dovest će do pozitivnih pomaka kod djeteta,
        bilo da je riječ o terapiji uživo ili o terapiji na daljinu.
      </Text>
      <Text as="p">Krešimir Perša, mag. logoped.</Text>
      <br />
      <Text as="p">
        <i>
          Izjava o odricanju odgovornosti - Videokonferencijski alati mogu se
          koristiti u komercijalne i nekomercijalne svrhe. Prije početka
          korištenja u komercijalne svrhe potrebno je provjeriti pridržavate li
          se svih zakona, pravilnika i propisa. Izjava o odricanju odgovornosti
          - Videokonferencijski alati mogu se koristiti u komercijalne i
          nekomercijalne svrhe. Prije početka korištenja u komercijalne svrhe
          potrebno je provjeriti pridržavate li se svih zakona, pravilnika i
          propisa.
        </i>
      </Text>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default DistanceSpeechTherapy;
