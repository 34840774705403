import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { Localize } from '@kokolingo/localisation';

import { Text } from '../../../components';

const MaterialPackage = ({ descriptionKey }) => {
  return (
    <div className="pricing-container teletherapy-pricing-container academy-institution-pricing-container">
      <div className="academy-institution-material-package-body pricing-benefits pricing-text">
        <Text as="h4">
          <Localize id={descriptionKey} />
        </Text>
      </div>
    </div>
  );
};

MaterialPackage.propTypes = {
  descriptionKey: PropTypes.string.isRequired,
};

export default memo(MaterialPackage);
