import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text, Card, Icon, ButtonNew, PricingItems } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

import './style.scss';

const ICON_CONFIG_FIRST = {
  1: 'like',
  2: 'devices',
};

const ICON_CONFIG_SECOND = {
  3: 'home2',
};

const WHY_CHOOSE_ITEMS = [
  {
    available: true,
    descriptionId: 'academyInstitution.Details.WhyChoose.Item1',
  },
  {
    available: true,
    descriptionId: 'academyInstitution.Details.WhyChoose.Item2',
  },
  {
    available: true,
    descriptionId: 'academyInstitution.Details.WhyChoose.Item3',
  },
  {
    available: true,
    descriptionId: 'academyInstitution.Details.WhyChoose.Item4',
  },
  {
    available: true,
    descriptionId: 'academyInstitution.Details.WhyChoose.Item5',
  },
  {
    available: true,
    descriptionId: 'academyInstitution.Details.WhyChoose.Item6',
  },
  {
    available: true,
    descriptionId: 'academyInstitution.Details.WhyChoose.Item7',
  },
  {
    available: true,
    descriptionId: 'academyInstitution.Details.WhyChoose.Item8',
  },
  {
    available: true,
    descriptionId: 'academyInstitution.Details.WhyChoose.Item9',
  },
];

const AcademyInstitutionDetails = () => {
  return (
    <div className="academy-institution-details">
      <Row
        className="short-about-cards-container justify-content-center align-items-center"
        noGutters
      >
        <Col xs={10}>
          <Row className="mt-5 ml-auto mr-auto mb-auto short-about-cards">
            {[...Array(2).keys()].map((i) => (
              <Col key={i} xs={12} sm={6} md={4}>
                <Card className="d-flex">
                  <Card.Badge>
                    <div className="academy-institution-card-icon-container">
                      <Icon name={ICON_CONFIG_FIRST[i + 1]} variant="academy" />
                    </div>
                  </Card.Badge>
                  <Text as="h3">
                    <Localize id={`academyInstitution.Details.Title${i + 1}`} />
                  </Text>
                  <Text>
                    <Localize
                      id={`academyInstitution.Details.Description${i + 1}`}
                    />
                  </Text>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="ml-auto mr-auto mb-auto short-about-cards short-about-cards-second-row">
            {[...Array(1).keys()].map((i) => (
              <Col key={i} xs={12} sm={6} md={4}>
                <Card
                  className={`d-flex${
                    ICON_CONFIG_SECOND[i + 3] === 'home2'
                      ? ' academy-card-home2'
                      : ''
                  }`}
                >
                  <Card.Badge>
                    <div className="academy-institution-card-icon-container">
                      <Icon
                        name={ICON_CONFIG_SECOND[i + 3]}
                        variant="academy"
                      />
                    </div>
                  </Card.Badge>
                  <Text as="h3">
                    <Localize id={`academyInstitution.Details.Title${i + 3}`} />
                  </Text>
                  <Text>
                    <Localize
                      id={`academyInstitution.Details.Description${i + 3}`}
                    />
                  </Text>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="mt-5 justify-content-center align-items-center">
            <ButtonNew
              variant="academy"
              onClick={appUrls.ACADEMY_INSTITUTION_FORM_URL}
            >
              <Localize id="academyInstitution.SectionHeader.CTA" />{' '}
              {emoji.POINTING_FINGER}
            </ButtonNew>
          </Row>
        </Col>
      </Row>
      <Row
        className="academy-institution-action academy-institution-academy-text flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <Col xs={10}>
          <div className="mt-5 mb-5">
            <Text as="h2" bold center>
              <Localize id="academyInstitution.Details.Section1.Title" />
            </Text>
            <Text as="h3">
              <Localize id="academyInstitution.Details.Section1.First" />
            </Text>
            <Text as="h3">
              <Localize
                id="academyInstitution.Details.Section1.Second"
                values={{
                  ul: (list) => <ul>{list}</ul>,
                  li: (listElement) => <li>{listElement}</li>,
                }}
              />
            </Text>
          </div>
        </Col>
      </Row>
      <Row
        className="short-about-cards-container justify-content-center align-items-center"
        noGutters
      >
        <Col xs={10}>
          <Row className="mt-5 ml-auto mr-auto mb-auto short-about-cards">
            <Col xs={12} sm={6} md={4}>
              <Card className="d-flex">
                <Card.Badge>
                  <Icon name="like" variant="academy" />
                </Card.Badge>
                <Text as="h3">
                  <Localize id="academyInstitution.Details.Section2.Card1.Title" />
                </Text>
                <Text>
                  <Localize id="academyInstitution.Details.Section2.Card1.Description" />
                </Text>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="d-flex">
                <Card.Badge>
                  <Icon name="home2" variant="academy" />
                </Card.Badge>
                <Text as="h3">
                  <Localize id="academyInstitution.Details.Section2.Card2.Title" />
                </Text>
                <Text>
                  <Localize id="academyInstitution.Details.Section2.Card2.Description" />
                </Text>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        className="academy-institution-action flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <Col xs={10}>
          <div className="mt-5">
            <Text as="h3" bold center>
              <Localize id="academyInstitution.Details.Section3.Description" />
            </Text>
            <Row className="mt-5 mb-5 justify-content-center align-items-center">
              <ButtonNew
                variant="academy"
                onClick={appUrls.ACADEMY_INSTITUTION_FORM_URL}
              >
                <Localize id="academyInstitution.SectionHeader.CTA" />{' '}
                {emoji.POINTING_FINGER}
              </ButtonNew>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="academy-institution-why-choose mt-5 mb-5">
        <Row noGutters>
          <Col xs={10}>
            <Text as="h2" center bold>
              <Localize id="academyInstitution.Details.WhyChoose.Title" />
            </Text>
            <PricingItems packages={WHY_CHOOSE_ITEMS} useText />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(AcademyInstitutionDetails);
