import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text, Card, Icon, ButtonNew, PricingItems } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';
import MaterialPackage from './MaterialPackage';

import './style.scss';

const ICON_CONFIG_FIRST = {
  1: 'like',
  2: 'devices',
};

const ICON_CONFIG_SECOND = {
  3: 'home2',
  4: 'puzzle',
};

const WHY_CHOOSE_ITEMS = [
  {
    available: true,
    descriptionId: 'academy.Details.Section1.Item1',
  },
  {
    available: true,
    descriptionId: 'academy.Details.Section1.Item2',
  },
  {
    available: true,
    descriptionId: 'academy.Details.Section1.Item3',
  },
  {
    available: true,
    descriptionId: 'academy.Details.Section1.Item4',
  },
  {
    available: true,
    descriptionId: 'academy.Details.Section1.Item5',
  },
  {
    available: true,
    descriptionId: 'academy.Details.Section1.Item6',
  },
  {
    available: true,
    descriptionId: 'academy.Details.Section1.Item7',
  },
];

const AcademyDetails = () => {
  return (
    <div className="academy-details">
      <Row
        className="short-about-cards-container justify-content-center align-items-center"
        noGutters
      >
        <Col xs={10}>
          <Row className="mt-5 ml-auto mr-auto mb-auto short-about-cards">
            {[...Array(2).keys()].map((i) => (
              <Col xs={12} sm={6} md={4}>
                <Card className="d-flex">
                  <Card.Badge>
                    <div className="academy-card-icon-container">
                      <Icon name={ICON_CONFIG_FIRST[i + 1]} variant="academy" />
                    </div>
                  </Card.Badge>
                  <Text as="h3">
                    <Localize id={`academy.Details.Title${i + 1}`} />
                  </Text>
                  <Text>
                    <Localize id={`academy.Details.Description${i + 1}`} />
                  </Text>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="ml-auto mr-auto mb-auto short-about-cards short-about-cards-second-row">
            {[...Array(2).keys()].map((i) => (
              <Col xs={12} sm={6} md={4}>
                <Card
                  className={`d-flex${
                    ICON_CONFIG_SECOND[i + 3] === 'home2'
                      ? ' academy-card-home2'
                      : ''
                  }`}
                >
                  <Card.Badge>
                    <div className="academy-card-icon-container">
                      <Icon
                        name={ICON_CONFIG_SECOND[i + 3]}
                        variant="academy"
                      />
                    </div>
                  </Card.Badge>
                  <Text as="h3">
                    <Localize id={`academy.Details.Title${i + 3}`} />
                  </Text>
                  <Text>
                    <Localize id={`academy.Details.Description${i + 3}`} />
                  </Text>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row
        className="academy-action flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <div className="mt-5">
          <Text as="h3" bold center>
            <Localize id="academy.Details.Action" />
          </Text>
        </div>
        <div className="mt-3 mb-5">
          <ButtonNew variant="academy" onClick={appUrls.ACADEMY_FORM_URL}>
            <Localize id="academy.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Row>
      <div className="academy-why-choose mt-5 mb-5">
        <Row noGutters>
          <Col xs={10}>
            <Text as="h2" center bold>
              <Localize id="academy.Details.Section1.Title" />
            </Text>
            <PricingItems packages={WHY_CHOOSE_ITEMS} useText />
          </Col>
        </Row>
      </div>
      <Row className="mb-5 academy-material-package-container justify-content-center">
        <Col md={3}>
          <MaterialPackage
            titleKey="academy.Details.Materials.Title1"
            descriptionKey="academy.Details.Materials.Description1"
            iconName="home2"
          />
        </Col>
        <Col md={3}>
          <MaterialPackage
            titleKey="academy.Details.Materials.Title2"
            descriptionKey="academy.Details.Materials.Description2"
            iconName="camera"
          />
        </Col>
        <Col md={3}>
          <MaterialPackage
            titleKey="academy.Details.Materials.Title3"
            descriptionKey="academy.Details.Materials.Description3"
            iconName="checklistTime2"
          />
        </Col>
      </Row>
      <Row
        className="academy-action flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <div className="mt-5">
          <Text as="h2" bold center>
            <Localize id="academy.Details.Action2.Title" />
          </Text>
          <Text as="h3" bold center>
            <Localize
              id="academy.Details.Action3"
              values={{
                u: (chunks) => <u>{chunks}</u>,
              }}
            />
          </Text>
        </div>
        <div className="mt-3 mb-5">
          <ButtonNew variant="academy" onClick={appUrls.ACADEMY_FORM_URL}>
            <Localize id="academy.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Row>
    </div>
  );
};

export default memo(AcademyDetails);
