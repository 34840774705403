import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Activities } from '../../index';
import { ButtonNew, Text } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

import './index.scss';

const SectionActivities = () => {
  return (
    <Container className="section-activities">
      <Col className="section-activities-header">
        <Text as="h2" bold center>
          <Localize id="homePage.SectionActivities.Title" />
        </Text>
        <Text center as="p" className="section-activities-subtitle">
          <Localize id="homePage.SectionActivities.Subtitle" />
        </Text>
      </Col>
      <Row className="mb-4">
        <Col>
          <Activities />
        </Col>
      </Row>
      <Col className="section-activities-cta">
        <ButtonNew onClick={appUrls.HOME}>
          <Localize id="homePage.SectionActivities.CTA" />{' '}
          {emoji.POINTING_FINGER}
        </ButtonNew>
      </Col>
    </Container>
  );
};

export default SectionActivities;
