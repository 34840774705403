import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import headerKoko from '../../../images/new-landing/header-koko.svg';

import { Text } from '../../../components';

import './style.scss';

const AcademyHeader = () => {
  return (
    <Row
      className="section-header section-header-small academy-header"
      noGutters
    >
      <Col className="section-header-title" md={{ offset: 1, span: 5 }} sm={12}>
        <img src={headerKoko} alt="Koko" />
        <Text as="h3">
          <Localize id="academy.SectionHeader.TitlePrefix" />
        </Text>
        <Text as="h1" bold>
          <Localize id="academy.SectionHeader.Title" />
        </Text>
        <Text as="h2">
          <Localize id="academy.SectionHeader.Subtitle" />
        </Text>
        <Text as="h3" className="mb-1">
          <Localize id="academy.SectionHeader.Description.First" />
        </Text>
        <Text as="h3">
          <Localize id="academy.SectionHeader.Description.Second" />
        </Text>
      </Col>
    </Row>
  );
};

export default memo(AcademyHeader);
