import React, { memo } from 'react';
import LazyLoad from 'react-lazyload';
import { Row, Col } from 'react-bootstrap';

import { Localize } from '@kokolingo/localisation';

import InstitutionCollaboration from '../../../images/hr/institutions-collaboration.png';
import { Text, YouTubeVideo } from '../../../components';
import INSTITUTIONS from '../../../constants/institutions';

import './index.scss';

const SectionInstitutions = () => {
  return (
    <>
      <Row className="institutions-title" noGutters>
        <Col
          lg={{ offset: 1, span: 6 }}
          md={{ offset: 1, span: 6 }}
          xs={{ offset: 1, span: 10 }}
        >
          <Text as="h2" bold center className="institutions-title__title">
            <Localize id="homePage.SectionInstitutions.Title" />
          </Text>
          <Text center as="p" className="institutions-title__subtitle">
            <Localize id="homePage.SectionInstitutions.Subtitle" />
          </Text>
        </Col>
        <Col
          lg={{ offset: 0, span: 4 }}
          md={{ offset: 1, span: 3 }}
          xs={{ offset: 1, span: 10 }}
          className="institutions-title__image-container"
        >
          <LazyLoad
            classNamePrefix="institutions-title__image-container__image"
            offset={100}
            once
          >
            <img
              alt="institution-collaboration"
              className="institutions-title__image-container__image"
              src={InstitutionCollaboration}
            />
          </LazyLoad>
        </Col>
      </Row>
      <Row className="institutions justify-content-center" noGutters>
        {INSTITUTIONS.map((institution) => (
          <Text center className="institutions__institution-rectangle">
            {institution}
          </Text>
        ))}
      </Row>
      <Row className="institutions-video  justify-content-center" noGutters>
        <Col
          lg={{ offset: 0, span: 4 }}
          sm={{ offset: 3, span: 6 }}
          xs={{ offset: 0, span: 10 }}
        >
          <LazyLoad
            classNamePrefix="institutions-video__video-frame"
            offset={100}
            once
          >
            <div className="institutions-video__video-frame">
              <YouTubeVideo videoId="s-vGwX5aYM8" />
            </div>
          </LazyLoad>
        </Col>
      </Row>
    </>
  );
};

export default memo(SectionInstitutions);
