import React, { memo } from 'react';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';
import { Localize, useIntlMessages } from '@kokolingo/localisation';

import { Text, Card } from '../../../components';
import testimonialImage1 from '../../../images/hr/mama-ema-testimonial.jpeg';
import testimonialImage2 from '../../../images/hr/mama-sanja-testimonial.jpg';

import './style.scss';

const TeletherapyReviews = () => {
  const messages = useIntlMessages();

  const settings = {
    slidesToShow: 1,
    infinite: true,
    className: 'slick-slider-home',
    dots: true,
    arrows: true,
    swipe: true,
    swipeToSlide: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          centerMode: true,
          variableWidth: process.env.GATSBY_LOCALE !== 'hr',
        },
      },
    ],
  };

  return (
    <div className="section-customers academy-section-customers mb-5">
      <Text as="h2" bold center>
        <Localize id="academy.testimonial.Title" />
      </Text>
      <Slider {...settings}>
        <Card size="large">
          <Card.Badge>
            <LazyLoad offset={100} once>
              <img
                src={testimonialImage1}
                alt="Testimonial 1"
                className="testimonial-image"
              />
            </LazyLoad>
          </Card.Badge>
          <span
            dangerouslySetInnerHTML={{
              __html: messages.Review.stars,
            }}
          />
          <Text as="p">
            <Localize id="teletherapy.testimonial.Description1" />
          </Text>
          <Text as="p" className="mb-0">
            <Localize id="teletherapy.testimonial.Name1" />
          </Text>
        </Card>

        <Card size="large">
          <Card.Badge>
            <LazyLoad offset={100} once>
              <img
                src={testimonialImage2}
                alt="Testimonial 2"
                className="testimonial-image"
              />
            </LazyLoad>
          </Card.Badge>
          <span
            dangerouslySetInnerHTML={{
              __html: messages.Review.stars,
            }}
          />
          <Text as="p">
            <Localize id="teletherapy.testimonial.Description2" />
          </Text>
          <Text as="p" className="mb-0">
            <Localize id="teletherapy.testimonial.Name2" />
          </Text>
        </Card>
      </Slider>
    </div>
  );
};

export default memo(TeletherapyReviews);
