import React from 'react';
import { Link } from 'gatsby';
import { Localize } from '@kokolingo/localisation';

import routes from '../../routes';
import appUrls from '../../constants/appUrls';

export default [
  {
    question: <Localize id="FAQ.Question1" />,
    answer: <Localize id="FAQ.Answer1" />,
  },
  {
    question: <Localize id="FAQ.Question2" />,
    answer: <Localize id="FAQ.Answer2" />,
  },
  {
    question: <Localize id="FAQ.Question3" />,
    answer: (
      <Localize
        id="FAQ.Answer3"
        values={{
          teletherapyForm: (linkText) => (
            <a
              className="faq-page-block__container__answer__link"
              href={appUrls.TELETHERAPY_FORM_URL}
            >
              {linkText}
            </a>
          ),
          teletherapyPhoneCallForm: (linkText) => (
            <a
              className="faq-page-block__container__answer__link"
              href={appUrls.TELETHERAPY_PHONE_CALL_FORM_URL}
            >
              {linkText}
            </a>
          ),
          newline: () => <br />,
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question4" />,
    answer: (
      <Localize
        id="FAQ.Answer4"
        values={{
          Link: (linkText) => (
            <Link
              className="faq-page-block__container__answer__link"
              to={routes.DISTANCE_SPEECH_THERAPY}
              title="Logo terapija na daljinu"
              hrefLang="hr"
            >
              {linkText}
            </Link>
          ),
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question5" />,
    answer: <Localize id="FAQ.Answer5" />,
  },
  {
    question: <Localize id="FAQ.Question6" />,
    answer: (
      <Localize
        id="FAQ.Answer6"
        values={{
          a: (linkText) => (
            <a
              className="faq-page-block__container__answer__link"
              href={appUrls.TELETHERAPY_FORM_URL}
            >
              {linkText}
            </a>
          ),
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question7" />,
    answer: <Localize id="FAQ.Answer7" />,
  },
  {
    question: <Localize id="FAQ.Question8" />,
    answer: (
      <Localize
        id="FAQ.Answer8"
        values={{
          newline: () => <br />,
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question9" />,
    answer: (
      <Localize
        id="FAQ.Answer9"
        values={{
          a: (linkText) => (
            <a
              className="faq-page-block__container__answer__link"
              href={appUrls.TELETHERAPY_FORM_URL}
            >
              {linkText}
            </a>
          ),
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question10" />,
    answer: <Localize id="FAQ.Answer10" />,
  },
  {
    question: <Localize id="FAQ.Question11" />,
    answer: <Localize id="FAQ.Answer11" />,
  },
  {
    question: <Localize id="FAQ.Question12" />,
    answer: <Localize id="FAQ.Answer12" />,
  },
  {
    question: <Localize id="FAQ.Question13" />,
    answer: (
      <Localize
        id="FAQ.Answer13"
        values={{
          newline: () => <br />,
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question14" />,
    answer: <Localize id="FAQ.Answer14" />,
  },
  {
    question: <Localize id="FAQ.Question15" />,
    answer: (
      <Localize
        id="FAQ.Answer15"
        values={{
          Link: (linkText) => (
            <Link
              className="faq-page-block__container__answer__link"
              to={routes.SPEECH_DEVELOPMENT}
              title="Razvoj glasova"
              hrefLang="hr"
            >
              {linkText}
            </Link>
          ),
          a: (linkText) => (
            <a
              className="faq-page-block__container__answer__link"
              href={appUrls.TELETHERAPY_FORM_URL}
            >
              {linkText}
            </a>
          ),
          newline: () => <br />,
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question16" />,
    answer: <Localize id="FAQ.Answer16" />,
  },
  {
    question: <Localize id="FAQ.Question17" />,
    answer: (
      <Localize
        id="FAQ.Answer17"
        values={{
          a: (linkText) => (
            <a
              className="faq-page-block__container__answer__link"
              href={appUrls.TELETHERAPY_FORM_URL}
            >
              {linkText}
            </a>
          ),
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question18" />,
    answer: <Localize id="FAQ.Answer18" />,
  },
  {
    question: <Localize id="FAQ.Question19" />,
    answer: <Localize id="FAQ.Answer19" />,
  },
  {
    question: <Localize id="FAQ.Question20" />,
    answer: <Localize id="FAQ.Answer20" />,
  },
  {
    question: <Localize id="FAQ.Question21" />,
    answer: <Localize id="FAQ.Answer21" />,
  },
  {
    question: <Localize id="FAQ.Question22" />,
    answer: (
      <Localize
        id="FAQ.Answer22"
        values={{
          Link: (linkText) => (
            <Link
              className="faq-page-block__container__answer__link"
              to={routes.LANGUAGE_DEVELOPMENT}
              title="Razvoj jezika i govora"
              hrefLang="hr"
            >
              {linkText}
            </Link>
          ),
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question23" />,
    answer: <Localize id="FAQ.Answer23" />,
  },
  {
    question: <Localize id="FAQ.Question24" />,
    answer: <Localize id="FAQ.Answer24" />,
  },
  {
    question: <Localize id="FAQ.Question25" />,
    answer: <Localize id="FAQ.Answer25" />,
  },
  {
    question: <Localize id="FAQ.Question26" />,
    answer: <Localize id="FAQ.Answer26" />,
  },
];
