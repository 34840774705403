import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize, useIntlMessages } from '@kokolingo/localisation';

import PropTypes from 'prop-types';
import Text from '../Text';

import './style.scss';

const Review = ({ img, from }) => {
  const messages = useIntlMessages();

  return (
    <Row className="header-review justify-content-center align-items-center">
      <Col
        xl={{ offset: 2, span: 8 }}
        lg={{ offset: 0, span: 11 }}
        md={{ offset: 0, span: 11 }}
        xs={{ offset: 3, span: 8 }}
      >
        <Row className="header-review-title align-items-center">
          <Col
            xl={{ offset: 0, span: 4 }}
            sm={{ offset: 0, span: 3 }}
            xs={{ offset: 0, span: 4 }}
          >
            <img src={img} alt="" />
          </Col>
          <Col
            className="header-review-title__text"
            lg={{ offset: 1, span: 7 }}
            xs={{ offset: 0, span: 8 }}
          >
            <Text>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: messages.Review.stars,
                }}
              />
            </Text>
            <Text as="h5">
              <Localize id={`Review.${from}.Title`} />
            </Text>
            <Text as="p">
              <Localize id={`Review.${from}.Subtitle`} />
            </Text>
          </Col>
        </Row>
        <Text as="p">
          <Localize id={`Review.${from}.Description`} />
        </Text>
      </Col>
    </Row>
  );
};

Review.propTypes = {
  img: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
};

export default Review;
