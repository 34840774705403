import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import cn from 'classnames';
import { Localize } from '@kokolingo/localisation';

import activityTypesItems from '../../../constants/activityTypesItems';
import { PricingItems, ButtonNew } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

const TeletherapyPricingPackage = ({ packageLabel }) => {
  const smartContainerClassName = cn({
    'pricing-main': true,
  });

  const pricingTitleClassName = cn('pricing-title', {
    'pricing-title-bg': false,
    smart: true,
  });

  const pricingGridContainerClassName = cn('pricing-grid-container', {
    smart: true,
  });

  const pricingBottomLabelKey = ['TelelogoPlus', 'Junior'].includes(
    packageLabel
  )
    ? 'pricing.PerMonth'
    : 'pricing.Once';

  const isTelelogo = packageLabel === 'Telelogo';
  const isJunior = packageLabel === 'Junior';

  return (
    <div className="pricing-container teletherapy-pricing-container">
      <div className={smartContainerClassName}>
        <h1 className={pricingTitleClassName}>
          <Localize id={`teletherapy.SectionPricing.${packageLabel}`} />
        </h1>

        <div className={pricingGridContainerClassName}>
          <div className="subscription-price__value__label">
            <Localize id="pricing.FullPrice" />
          </div>
          <div className="subscription-price__value__label" />
          <div
            style={{ visibility: 'hidden' }}
            className="subscription-price__value"
          >
            <p>
              <Localize
                id={`teletherapy.SectionPricing.${packageLabel}PriceFullEuros`}
                values={{
                  span: (priceOld) => (
                    <span className="subscription-price">{priceOld}</span>
                  ),
                  space: ' ',
                }}
              />
            </p>
          </div>
          <div className="subscription-price__value">
            <p
              style={{
                position: 'absolute',
                right: '0',
                bottom: '0',
              }}
            >
              <Localize
                id={`teletherapy.SectionPricing.${packageLabel}PriceDiscountEuros`}
                values={{
                  span: (priceNew) => (
                    <span className="subscription-price--new">{priceNew}</span>
                  ),
                  space: ' ',
                }}
              />
            </p>
          </div>
          <div
            style={{ visibility: 'hidden' }}
            className="subscription-price--bottom"
          >
            <Localize id={pricingBottomLabelKey} />
          </div>
          <div className="subscription-price--bottom">
            <Localize id={pricingBottomLabelKey} />
          </div>
        </div>
        <p className="pricing-text pricing-description">
          <Localize
            id={`teletherapy.SectionPricing.${packageLabel}Description`}
          />
          {isJunior && (
            <>
              <br />
              <Localize id="teletherapy.SectionPricing.JuniorDescription2" />
            </>
          )}
        </p>
      </div>
      <div className="pricing-benefits pricing-text">
        <p>
          <b>
            <Localize id="teletherapy.SectionPricing.TypesOfActivities" />
          </b>
        </p>
        <PricingItems
          packages={activityTypesItems[packageLabel.toUpperCase()]}
        />
      </div>
      <div className="teletherapy-cta-button-container">
        <ButtonNew
          onClick={
            isTelelogo
              ? appUrls.TELETHERAPY_FORM_URL
              : appUrls.TELETHERAPY_PHONE_CALL_FORM_URL
          }
        >
          <Localize id="teletherapy.SectionHeader.CTA" />{' '}
          {emoji.POINTING_FINGER}
        </ButtonNew>
      </div>
    </div>
  );
};

TeletherapyPricingPackage.propTypes = {
  packageLabel: PropTypes.oneOf(['Telelogo', 'TelelogoPlus', 'Junior'])
    .isRequired,
};

export default memo(TeletherapyPricingPackage);
