import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { CardPost } from '../..';
import routes from '../../../routes';

import './index.scss';

import TherapistWithChild from '../../../images/educational-corner/blog28/Logoped-vjezba-izgovor-glasa-s-djetetom.jpg';
import ParentWithChild1 from '../../../images/educational-corner/blog33/roditelj-s-djetetom1.jpg';
import ParentWithChild4 from '../../../images/educational-corner/blog33/roditelj-s-djetetom4.jpg';

const Index = () => {
  return (
    <Row className="mb-3 mt-3 blog-latest-articles">
      <Col xs={12} sm={6} md={4}>
        <CardPost
          title="Dislalija - poremećaj izgovora glasova"
          subtitle="Dislalija, poznata i kao artikulacijski poremećaj ili poremećaj izgovora glasova, odnosi se na nepravilan izgovor pojedinih glasova kod djece i odraslih osoba"
          img={ParentWithChild1}
          url={routes.DYSLALIA}
        />
      </Col>
      <Col xs={12} sm={6} md={4}>
        <CardPost
          title="Artikulacijsko – fonološki poremećaji"
          subtitle="Nepravilan izgovor glasova jedan je od najčešćih problema zbog kojeg roditelji danas"
          img={TherapistWithChild}
          url={routes.SPEECH_DISORDER}
        />
      </Col>
      <Col xs={12} sm={6} md={4}>
        <CardPost
          title="Online logopedske vježbe"
          subtitle="Za provođenje logopedske terapije osposobljen je stručnjak logoped"
          img={ParentWithChild4}
          url={routes.ONLINE_SPEECH_THERAPY_EXERCISES}
        />
      </Col>
    </Row>
  );
};

export default Index;
