import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import headerKoko from '../../../images/new-landing/header-koko.svg';
import headerHero from '../../../images/teletherapy_header.jpg';
import mamaTestimonial from '../../../images/hr/mama-testimonial.jpg';

import { Review, Text, ButtonNew } from '../../../components';
import appUrls from '../../../constants/appUrls';
import routes from '../../../routes';
import emoji from '../../../constants/emoji';

import './style.scss';

const TeletherapyHeader = () => {
  return (
    <Row className="section-header teletherapy-header" noGutters>
      <Col className="section-header-title" md={{ offset: 1, span: 5 }} sm={12}>
        <img src={headerKoko} alt="Koko" />
        <Text as="h1" bold>
          <Localize id="teletherapy.SectionHeader.Title" />
        </Text>
        <Text as="h2">
          <Localize id="teletherapy.SectionHeader.Subtitle" />
        </Text>
        <Text as="h3">
          <Localize id="teletherapy.SectionHeader.Description" />
        </Text>
        <div>
          <ButtonNew onClick={appUrls.TELETHERAPY_PHONE_CALL_FORM_URL}>
            <Localize id="teletherapy.SectionHeader.CTA" />{' '}
            {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
        <Text as="h3" />
        <Text as="h3">
          <Localize id="teletherapy.SectionHeader.Description2" />
        </Text>
        <div className="mb-5">
          <ButtonNew variant="academy" onClick={routes.ACADEMY}>
            <Localize id="teletherapy.SectionHeader.CTA2" />{' '}
            {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Col>

      <Col
        className="section-header-hero hero-height"
        md={{ offset: 1, span: 4 }}
        sm={12}
      >
        <picture className="hero-circle hero-circle-secondary">
          <img src={headerHero} alt="" />
        </picture>
        <Review img={mamaTestimonial} from="mamaAna" />
      </Col>
    </Row>
  );
};

export default memo(TeletherapyHeader);
