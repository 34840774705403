import React, { memo } from 'react';
import { Localize } from '@kokolingo/localisation';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';

import { Text, ButtonNew } from '../../../components';
import PricingPackage from './PricingPackage';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

import './style.scss';

const TeletherapyPricing = () => {
  return (
    <div className="teletherapy-pricing">
      <Row className="section-pricing" noGutters>
        <Text as="h2" className="my-4" bold>
          <Localize id="teletherapy.SectionPricing.Title" />
        </Text>
        <div className="fade show">
          <div className="d-none d-md-block">
            <div className="container-fluid">
              <Row className="row">
                <Col sm={4}>
                  <PricingPackage packageLabel="Telelogo" />
                </Col>
                <Col sm={4}>
                  <PricingPackage packageLabel="Junior" />
                </Col>
                <Col sm={4}>
                  <PricingPackage packageLabel="TelelogoPlus" />
                </Col>
              </Row>
            </div>
          </div>

          <div className="packages-container-mobile">
            <Tabs
              defaultActiveKey="Junior"
              className="justify-content-center tab-styling"
              style={{ height: '39.5px' }}
            >
              <Tab
                key="Telelogo"
                eventKey="Telelogo"
                id="Telelogo"
                title="Online logopedsko savjetovanje"
                className="tab-pane"
              >
                <div id="Telelogo" className="nav-link fade in active show">
                  <PricingPackage packageLabel="Telelogo" />
                </div>
              </Tab>
              <Tab
                key="Junior"
                eventKey="Junior"
                id="Junior"
                title="Kokolingo Ekspert Junior"
                className="tab-pane"
              >
                <div id="Junior" className="nav-link fade in active show">
                  <PricingPackage packageLabel="Junior" />
                </div>
              </Tab>
              <Tab
                key="TelelogoPlus"
                eventKey="TelelogoPlus"
                id="TelelogoPlus"
                title="Kokolingo Ekspert"
                className="tab-pane"
              >
                <div id="TelelogoPlus" className="nav-link fade in active show">
                  <PricingPackage packageLabel="TelelogoPlus" />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Row>

      <Row noGutters>
        <Col xs={{ span: 10, offset: 1 }}>
          <Text as="h3" bold>
            <Localize id="teletherapy.SectionPricing.TelelogoDetailsTitle" />
          </Text>
          <Text>
            <Localize id="teletherapy.SectionPricing.TelelogoDetails" />
          </Text>
        </Col>
      </Row>

      <Row noGutters>
        <Col xs={{ span: 10, offset: 1 }}>
          <Text as="h3" bold>
            <Localize id="teletherapy.SectionPricing.JuniorDetailsTitle" />
          </Text>
          <Text>
            <Localize id="teletherapy.SectionPricing.JuniorDetails" />
          </Text>
        </Col>
      </Row>

      <Row noGutters className="my-3">
        <Col xs={{ span: 10, offset: 1 }}>
          <Text as="h3" bold>
            <Localize id="teletherapy.SectionPricing.TelelogoPlusDetailsTitle" />
          </Text>
          <Text>
            <Localize id="teletherapy.SectionPricing.TelelogoPlusDetails" />
          </Text>
          <Row className="mb-5 align-items-center justify-content-center">
            <ButtonNew onClick={appUrls.TELETHERAPY_PHONE_CALL_FORM_URL}>
              <Localize id="teletherapy.SectionHeader.CTA" />{' '}
              {emoji.POINTING_FINGER}
            </ButtonNew>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default memo(TeletherapyPricing);
