import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import headerKoko from '../../../images/new-landing/header-koko.svg';

import { Text } from '../../../components';

import MaterialPackage from './MaterialPackage';

import './style.scss';

const AcademyInstitutionHeader = () => {
  return (
    <>
      <div className="section-header section-header-small academy-institution-header">
        <Col
          className="section-header-title"
          md={{ offset: 1, span: 5 }}
          sm={12}
        >
          <img src={headerKoko} alt="Koko" />
          <Text as="h1" bold>
            <Localize id="academyInstitution.SectionHeader.Title" />
          </Text>
          <Text as="h2">
            <Localize id="academyInstitution.SectionHeader.Subtitle" />
          </Text>
        </Col>
        <Row className="mt-5 academy-material-package-container justify-content-center">
          <Col md={3}>
            <MaterialPackage descriptionKey="academyInstitution.SectionHeader.Description.First" />
          </Col>
          <Col md={3}>
            <MaterialPackage descriptionKey="academyInstitution.SectionHeader.Description.Second" />
          </Col>
          <Col md={3}>
            <MaterialPackage descriptionKey="academyInstitution.SectionHeader.Description.Third" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(AcademyInstitutionHeader);
