import React, { memo } from 'react';
import { Col, Row, ButtonGroup } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import academyInstitution1 from '../../../images/academy_institution_image1.png';
import academyInstitution2 from '../../../images/academy_institution_image2.png';

import { Text, ButtonNew, YouTubeVideo } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';
import routes from '../../../routes';

import './style.scss';

const AcademyInstitutionIntro = () => {
  return (
    <div className="academy-institution-intro">
      <Row className="mt-5 justify-content-center align-items-center" noGutters>
        <Col xs={10}>
          <div className="academy-institution-intro-buttons-container mb-5">
            <ButtonGroup>
              <ButtonNew
                className="button-new__light"
                variant="primary"
                onClick={routes.ACADEMY}
              >
                <Localize id="pricing.ParentsTitle" />
              </ButtonNew>
              <ButtonNew variant="academy">
                <Localize id="pricing.FacilitiesTitle" />
              </ButtonNew>
            </ButtonGroup>
          </div>
        </Col>
        <Col xs={10}>
          <div className="academy-institution-video">
            <YouTubeVideo
              videoId="1fScJnqqCv8"
              videoImgUrl="https://img.youtube.com/vi/1fScJnqqCv8/maxresdefault.jpg"
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-5 align-items-center justify-content-center" noGutters>
        <Col xs={10}>
          <Text as="h3">
            <Localize id="academyInstitution.SectionIntro.Title.First" />
          </Text>
          <Text as="h3">
            <Localize id="academyInstitution.SectionIntro.Title.Second" />
          </Text>
        </Col>
      </Row>
      <Row className="mt-3 align-items-center justify-content-center" noGutters>
        <Col md={{ offset: 0, span: 6 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-0 mb-3">
            <Localize id="academyInstitution.SectionIntro.Title.Third" />
          </Text>
          <Text>
            <Localize
              id="academyInstitution.SectionIntro.Description"
              values={{
                ul: (list) => <ul>{list}</ul>,
                li: (listElement) => <li>{listElement}</li>,
              }}
            />
          </Text>
        </Col>
        <Col
          className="hero-height"
          md={{ offset: 1, span: 3 }}
          xs={{ offset: 0, span: 9 }}
        >
          <picture className="hero-circle hero-circle-tertiary">
            <img src={academyInstitution1} alt="" className="mt-4 mt-sm-0" />
          </picture>
        </Col>
      </Row>
      <Row
        className="academy-institution-action flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <Col xs={10}>
          <div className="mt-5">
            <Text as="h3" bold center>
              <Localize id="academyInstitution.SectionIntro.Action.First" />
            </Text>
          </div>
          <Row className="mt-3 justify-content-center align-items-center">
            <ButtonNew
              variant="academy"
              onClick={appUrls.ACADEMY_INSTITUTION_FORM_URL}
            >
              <Localize id="academyInstitution.SectionHeader.CTA" />{' '}
              {emoji.POINTING_FINGER}
            </ButtonNew>
          </Row>
          <div className="mt-3 mb-5">
            <Text as="h3" bold center>
              <Localize id="academyInstitution.SectionIntro.Action.Second" />
            </Text>
          </div>
        </Col>
      </Row>
      <Row className="mt-5 justify-content-center align-items-center" noGutters>
        <Col md={{ offset: 0, span: 4 }}>
          <picture className="hero-circle d-none d-md-block">
            <img src={academyInstitution2} alt="" />
          </picture>
        </Col>
        <Col md={{ offset: 0, span: 6 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-md-0 mb-3">
            <Localize id="academyInstitution.SectionIntro.Title2" />
          </Text>
          <Text>
            <Localize
              id="academyInstitution.SectionIntro.Description2"
              values={{
                ul: (list) => <ul>{list}</ul>,
                li: (listElement) => <li>{listElement}</li>,
                u: (chunks) => <u>{chunks}</u>,
              }}
            />
          </Text>
        </Col>
      </Row>
      <Row
        className="mt-5 mb-5 align-items-center justify-content-center"
        noGutters
      >
        <Col xs={10}>
          <Text as="h3" bold className="mt-4 mt-md-0 mb-3">
            <Localize id="academyInstitution.SectionIntro.End2" />
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export default memo(AcademyInstitutionIntro);
