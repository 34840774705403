import * as React from 'react';
import { Link } from 'gatsby';

import { Text, BlogImage, BlogInfographics, BlogLink } from '../../components';

import { PageNew, SectionBlog } from '..';

import routes from '../../routes';

import infographic from '../../images/educational-corner/blog11/infografika.png';
import logatoms from '../../images/educational-corner/blog11/Logatomi-i-rijeci.png';
import speechOrgans from '../../images/educational-corner/blog11/Pokretljivost-govornih-organa.png';
import sentences from '../../images/educational-corner/blog11/Recenice.png';
import auditoryDifferentiation from '../../images/educational-corner/blog11/Slusno-razlikovanje-glasova.png';

export default () => {
  return (
    <PageNew
      title="KOKOLINGO – Online Logopedske Vježbe"
      description="Online logopedske vježbe uz KOKOLINGO koji je strukturiran
      prema standardnom načinu provođenja logopedskih vježbi za
      dobivanje pravilnog izgovora."
      headline="Online logopedske vježbe"
    >
      <Text as="p">
        Za provođenje logopedske terapije osposobljen je stručnjak logoped,
        odnosno magistar logopedije. Logopedske vježbe uobičajeno se provode
        uživo, ali moguće ih je i odrađivati putem{' '}
        <Link
          to={routes.DISTANCE_SPEECH_THERAPY}
          title="Logopedska terapija na daljinu"
          target="_blank"
          rel="noopener noreferrer"
          hrefLang="hr"
        >
          <b>videokonferencijskih alata</b>
        </Link>
        , odnosno online. Tijekom terapije logoped se može koristiti različitim
        materijalima - igračkama, slagalicama, radnim listovima itd., ovisno o
        vrsti teškoće koju dijete ima.{' '}
        <b>
          Najčešći poremećaj s kojim se logopedi susreću u radu jest
          artikulacijski poremećaj
        </b>
        , odnosno poremećaj izgovora određenih glasova. Najčešće se očituje kao
        omisija glasova, odnosno nečujna realizacija glasova (primjerice umjesto
        soba dijete kaže _oba), supstitucija glasova, odnosno zamjena glasova
        (primjerice umjesto led dijete kaže ved) te distorzija glasova, odnosno
        nepravilan izgovor glasova (primjerice grleni izgovor glasa R u riječi
        rak). Upravo za tu vrstu poremećaja osmišljena je online aplikacija{' '}
        <BlogLink>Kokolingo</BlogLink> koja je{' '}
        <b>
          strukturirana prema standardnom načinu provođenja logopedskih vježbi
          za dobivanje pravilnog izgovora
        </b>
        .
      </Text>
      <Text as="h2">Struktura digitalne vježbenice kokolingo</Text>
      <Text as="p">
        <b>
          Vježbe za dobivanje pravilne artikulacije provode se u nekoliko faza
          koje se mogu preklapati
        </b>
        . Na početku vježbi fokus je na predvještinama potrebnim za pravilan
        izgovor - slušnom razlikovanju glasova i motoričkoj pokretljivosti
        govornih organa. Kada dijete svlada te vještine, kreće se s vježbama za
        dobivanje pravilnog izgovora s pomoću lista logatoma - slogova bez
        značenja, i riječi. Kada dijete ovlada pravilnim izgovorom unutar
        logatoma i riječi, potrebno ga je i automatizirati, upotrebljavajući
        duže jezične strukture, odnosno rečenice i priče. Ovakav slijed vježbi
        prisutan je i u <BlogLink>Kokolingo</BlogLink> vježbenici.
      </Text>
      <Text as="h2">Slušno razlikovanje glasova</Text>
      <Text as="p">
        Prije nego što dijete krene s logopedskim vježbama za pravilan izgovor,
        važno je da nauči razlikovati međusobno slične glasove, poput glasova{' '}
        <i>Š</i> i <i>Ž</i> ili <i>S</i> i <i>Z</i>.{' '}
        <b>Slušno razlikovanje glasova preduvjet je pravilnom izgovoru</b>. U
        Kokolingo vježbenici ova se aktivnost nalazi na prvom području i naziva
        se Pažljivo slušanje. Slušno razlikovanje može se vježbati dvjema
        aktivnostima - Sakupljanjem blaga i Bojenjem. Na ovaj način dijete uči
        razlikovati vrlo slične glasove, primjerice treba odrediti u kojim
        riječima čuje glas <i>K</i>, a u kojim riječima čuje glas <i>G</i>.
        Riječi su u ovim aktivnostima predstavljene jasnim slikama na koje
        dijete treba kliknuti i čuti pravilan izgovor te riječi, odnosno
        uvježbavanoga glasa, primjerice glasa <i>K</i> ili glasa <i>G</i>. Svaki
        pojam može se više puta ponoviti. Na ovaj način dijete vježba slušnu
        pažnju i uči razlikovati međusobno slične glasove.
      </Text>
      <BlogImage
        imgSrc={auditoryDifferentiation}
        imgAlt="Primjer igara za vjezbanje slusnog razlikovanja glasova"
        imgTitle="Slusno razlikovanje glasova"
      />
      <Text as="h2">Pokretljivost govornih organa</Text>
      <Text as="p">
        <b>
          Kako bi dijete moglo pravilno izgovarati glasove, mora na pravilan
          način pokretati svoje govorne organe - usne, donju čeljust i jezik
        </b>
        . Zbog toga se provode vježbe artikulacijske gimnastike, odnosno
        gimnastike govornih organa. Postoji više vrsta vježbi ovog tipa,
        primjerice statičke vježbe u kojima dijete mora nekoliko sekundi
        zadržati jezik ili usne u određenom položaju i dinamičke vježbe u kojima
        dijete mora pokretati svoj jezik ili usne na određeni način.
      </Text>
      <Text as="p">
        U Kokolingo vježbenici ove vježbe nalaze se na prvom području pod imenom
        Gusarska gimnastika, jer ih izvodi gusar Srećko, a dijete nakon njega
        mora ponoviti vježbu. Osim što ovim vježbama poboljšavamo pokretljivost
        govornih organa, jačamo i njihove mišiće. S aktivnostima artikulacijske
        gimnastike ne treba pretjerivati, odnosno poželjno ih je izvesti samo
        nekoliko, u trajanju do maksimalno pet minuta da ne dođe do zamora
        govornih organa.
      </Text>
      <BlogImage
        imgSrc={speechOrgans}
        imgAlt="Gusarska gimnastika za vjezbanje pokretljivosti govornih organa"
        imgTitle="Pokretljivost govornih organa"
      />
      <Text as="h2">Logatomi i riječi</Text>
      <Text as="p">
        Liste logatoma, odnosno slogova bez značenja i riječi materijali su za
        vježbanje pravilnog izgovora. Takve liste izrađuju logopedi, a njihov
        sadržaj ovisi o glasu koji treba korigirati.{' '}
        <b>
          Ovakve liste pažljivo se izrađuju, tako da se u njima ne nalaze riječi
          koje sadržavaju i bliske glasove kojima djeca zamjenjuju određeni glas
        </b>
        . Primjerice, kada uvježbavamo glas <i>Š</i>, nećemo se koristiti
        riječima koje u sebi imaju i glas <i>S</i>, poput riječi <i>šest</i> ili{' '}
        <i>suša</i>, jer djeca često zamjenjuju glas <i>Š</i> s glasom <i>S</i>.
        Takve riječi treba uvoditi postupno, tek kada dijete svlada pravilan
        izgovor određenoga glasa u riječima koje ne sadržavaju i bliske glasove.
      </Text>
      <Text as="p">
        U Kokolingo vježbenici logatomi su nazvani Čudnovate riječi, s obzirom
        na to da zvuče pomalo “čudnovato”. Možemo ih vježbati u dvjema
        aktivnostima, Popni se i Otkrij. U ovim aktivnostima dijete treba
        izgovoriti ili samo jedan logatom, primjerice <i>ŠA</i> ili <i>TRA</i>,
        ili izgovara logatomski par, primjerice <i>ŠA-ŠE</i> ili <i>TRA-TRI</i>.
        Budući da se pravilan izgovor najviše uvježbava s pomoću lista riječi,
        za njih su osmišljene tri aktivnosti - Brbljave kartice, Pronađi riječi
        i Memori kartice. Ovim aktivnostima uvježbavaju se različite liste
        riječi, one koje sadržavaju određeni glas na početku riječi, u sredini i
        na kraju. Na taj način dijete sustavno usvaja pravilan izgovor
        određenoga glasa, od one pozicije u kojoj je najlakše dobiti pravilan
        izgovor do onih u kojima je malo složenije dobiti pravilan izgovor. Osim
        što ovim aktivnostima korigiramo izgovor, poboljšavamo i pažnju i
        koncentraciju te vježbamo radno pamćenje.
      </Text>
      <BlogImage
        imgSrc={logatoms}
        imgAlt="Primjer igara za vjezbanje logatoma i rijeci"
        imgTitle="Logatomi i rijeci"
      />
      <Text as="h2">Rečenice</Text>
      <Text as="p">
        S pomoću logatoma i riječi dijete uči pravilan izgovor izolirano,
        odnosno u manjim jezičnim cjelinama.{' '}
        <b>
          Tek s rečenicama dijete će automatizirati pravilan izgovor u većim
          cjelinama, počevši od kraćih rečenica, a zatim vježbom u dužim i
          složenijim rečenicama
        </b>
        . Uvježbavanjem izgovora u rečenicama radimo na tome da dijete počne
        pravilno izgovarati određeni glas i u spontanom govoru. U Kokolingo
        digitalnoj vježbenici nalaze se četiri aktivnosti za automatizaciju
        pravilnog izgovora. To su dvije aktivnosti koje sadržavaju zadatke s
        rečenicama - Kolo sreće i Nadopunjavanje te dvije aktivnosti koje
        sadržavaju zadatke s pričama - Čitajmo zajedno i Pročitajmo i
        promislimo. Ovi zadatci pružaju logičan slijed, od kraćih i
        jednostavnijih rečenica do dužih i složenijih u pričama. Naravno, ako
        dijete ne uspijeva ponoviti duge rečenice, u izgovoru ih možemo
        podijeliti na dvije ili tri kraće rečenice jer ne želimo da dijete bude
        frustrirano, nego motivirano za rad.
      </Text>
      <BlogImage
        imgSrc={sentences}
        imgAlt="Primjer igara za vjezbanje recenica"
        imgTitle="Recenice"
      />
      <Text as="p">
        Pri vježbanju pravilnog izgovora moramo biti sustavni, dosljedni i
        motivirani za rad. Naravno, da bi dijete odradilo planirane aktivnosti,
        zadatci moraju biti i zabavni.{' '}
        <b>
          Kokolingo vježbenica objedinjuje sustavnost u radu, praćenje napretka,
          motivatore i zabavne aktivnosti
        </b>
        . Budući da se radi o digitalnom alatu, ova vježbenica idealna je za
        djecu koju ne možemo motivirati za rad tiskanim materijalima ili
        konkretima. Olakšava terapijski rad logopedima, a roditeljima vježbe kod
        kuće i što je najbitnije - djeci je zabavna.
      </Text>
      <Text as="p">Krešimir Perša, mag. logoped</Text>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};
