import React from 'react';
import HubspotForm from 'react-hubspot-form';

export default () => (
  <HubspotForm
    portalId="8366494"
    formId="03f8a19e-03d2-42a7-a9be-8c6d0deea441"
    locale="hr"
    region="na1"
  />
);
