import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { ButtonGroup, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { html } from '../../../utils';
import routes from '../../../routes';
import { PricingItems, Text, ButtonNew } from '../../../components';
import activityTypesItems from '../../../constants/activityTypesItems';
import PricingPackage from '../PricingPackage';
import additionalBenefitsItems from '../../../constants/additionalBenefitsItems';

import './index.scss';

import papigaKoko25 from '../../../images/papiga_koko_ustanove_25.svg';
import papigaKoko10 from '../../../images/papiga_koko_ustanove_10.svg';
import papigaKoko5 from '../../../images/papiga_koko_ustanove_5.svg';

const DefaultSectionPricing = require('../../default/SectionPricing').default;

const PARENTS = 'PARENTS';
const FACILITIES = 'FACILITIES';

const kokolingoPro = [
  {
    numberOfUsers: 25,
    price: '2.254,96',
    altPrice: '16.990',
    image: papigaKoko25,
  },
  {
    numberOfUsers: 10,
    price: '1.325,90',
    altPrice: '9.990',
    devices: 4,
    image: papigaKoko10,
  },
  {
    numberOfUsers: 5,
    price: '927,73',
    altPrice: '6.990',
    devices: 2,
    image: papigaKoko5,
  },
];

const SectionPricing = () => {
  const [activeKey, setActiveKey] = useState(PARENTS);

  useEffect(() => {
    function hashChange() {
      if (window.location.hash === html.anchorUrl(routes.PARENTS_ANCHOR)) {
        setActiveKey(PARENTS);
      } else if (
        window.location.hash === html.anchorUrl(routes.FACILITIES_ANCHOR)
      ) {
        setActiveKey(FACILITIES);
      }
    }

    window.addEventListener('hashchange', hashChange);

    return () => {
      window.removeEventListener('hashchange', hashChange);
    };
  }, []);

  return (
    <Row noGutters className="section-pricing-main">
      <div id="cjenik" />
      <ButtonGroup>
        <ButtonNew
          className={activeKey !== PARENTS ? 'button-new__light' : ''}
          onClick={() => setActiveKey(PARENTS)}
        >
          <Localize id="pricing.ParentsTitle" />
        </ButtonNew>
        <ButtonNew
          className={activeKey !== FACILITIES ? 'button-new__light' : ''}
          onClick={() => setActiveKey(FACILITIES)}
        >
          <Localize id="pricing.FacilitiesTitle" />
        </ButtonNew>
      </ButtonGroup>
      <div hidden={activeKey !== PARENTS}>
        <DefaultSectionPricing>
          {(props) => <PricingPackage {...props} />}
        </DefaultSectionPricing>
      </div>
      <div hidden={activeKey !== FACILITIES}>
        <Row className="section-pricing" noGutters>
          <div className="pricing-title-description">
            <Text as="h3" color="light" bold>
              <Localize id="pricing.Facilities" />
            </Text>
            <Text as="p" center color="light">
              <Localize id="pricing.FacilitiesDescription" />
            </Text>
          </div>
          <div className="fade show">
            <div className="pro-packages-container">
              <div className="container-fluid">
                <Row className="nowrap">
                  <div className="col">
                    {kokolingoPro.map((item, index) => (
                      <div
                        key={index}
                        className="pricing-container pricing-card row"
                      >
                        <div className="row w-75 mx-2">
                          <div className="pricing-price-container w-60 mx-2">
                            <Text as="h4" bold>
                              <Localize
                                id="pricing.ProLicenseLabel"
                                values={{
                                  numberOfUsers: item.numberOfUsers.toString(),
                                }}
                              />{' '}
                              <b>
                                <span style={{ color: '#cc200a' }}>*</span>
                              </b>
                            </Text>
                            <a
                              className="button-new button-new__primary button-new__small"
                              href="/ustanove"
                              target="_blank"
                            >
                              <Localize id="pricing.SearchOffer" />
                            </a>
                          </div>
                          <div className="pricing-price-container w-40">
                            <span className="pro-license-price">
                              {item.price} <div>€</div>
                              <b>
                                <span style={{ color: '#cc200a' }}>**</span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div className="pro-license pro-image w-25">
                          <LazyLoad offset={100} once>
                            <img src={item.image} alt={item.image} />
                          </LazyLoad>
                        </div>
                      </div>
                    ))}
                    <div className="pricing-title-description pricing-explanation">
                      <Localize
                        id="pricing.ProLicenseDescription"
                        values={{
                          star1: (
                            <b>
                              <span style={{ color: '#cc200a' }}>*</span>
                            </b>
                          ),
                          star2: (
                            <b>
                              <span style={{ color: '#cc200a' }}>**</span>
                            </b>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="pricing-container">
                    <div className="pricing-benefits pricing-text">
                      <Text as="h3" bold>
                        <Localize id="pricing.ProLicenseFeatures" />
                      </Text>
                      <p>
                        <b>
                          <Localize id="pricing.TypesOfActivities" />
                        </b>
                      </p>
                      <PricingItems packages={activityTypesItems.PREMIUM} />
                      <p>
                        <b>
                          <Localize id="pricing.AdditionalBenefits" />
                        </b>
                      </p>
                      <PricingItems
                        packages={additionalBenefitsItems.PREMIUM}
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </div>
            <div className="pro-packages-container-mobile">
              {kokolingoPro.map((item, index) => (
                <div key={index} className="pricing-container pricing-card row">
                  <div className="row w-75 mx-2">
                    <div className="pricing-price-container w-60 mx-2">
                      <Text as="h4" bold>
                        <Localize
                          id="pricing.ProLicenseLabel"
                          values={{
                            numberOfUsers: item.numberOfUsers.toString(),
                          }}
                        />{' '}
                        <b>
                          <span style={{ color: '#cc200a' }}>*</span>
                        </b>
                      </Text>
                      <a
                        className="button-new button-new__primary button-new__small"
                        href="/ustanove"
                        target="_blank"
                      >
                        <Localize id="pricing.SearchOffer" />
                      </a>
                    </div>
                    <div className="pricing-price-container w-40">
                      <span className="pro-license-price">
                        {item.price}
                        <div className="pro-license-price">
                          €
                          <b>
                            <span style={{ color: '#cc200a' }}> **</span>
                          </b>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="pro-license pro-image w-25">
                    <LazyLoad offset={100} once>
                      <img src={item.image} alt={item.image} />
                    </LazyLoad>
                  </div>
                </div>
              ))}
              <div className="pricing-container">
                <div className="pricing-benefits pricing-text">
                  <Text as="h3" bold>
                    <Localize id="pricing.ProLicenseFeatures" />
                  </Text>
                  <p>
                    <b>
                      <Localize id="pricing.TypesOfActivities" />
                    </b>
                  </p>
                  <PricingItems packages={activityTypesItems.PREMIUM} />
                  <p>
                    <b>
                      <Localize id="pricing.AdditionalBenefits" />
                    </b>
                  </p>
                  <PricingItems packages={additionalBenefitsItems.PREMIUM} />
                </div>
              </div>
              <div className="pricing-title-description pricing-explanation">
                <div className="pricing-title-description pricing-explanation">
                  <Localize
                    id="pricing.ProLicenseDescription"
                    values={{
                      star1: (
                        <b>
                          <span style={{ color: '#cc200a' }}>*</span>
                        </b>
                      ),
                      star2: (
                        <b>
                          <span style={{ color: '#cc200a' }}>**</span>
                        </b>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </Row>
  );
};

export default SectionPricing;
