import React, { memo } from 'react';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';
import { Localize, useIntlMessages } from '@kokolingo/localisation';

import { Text, Card } from '../../../components';

import './style.scss';

const AcademyReviews = () => {
  const messages = useIntlMessages();

  const settings = {
    slidesToShow: 1,
    infinite: true,
    className: 'slick-slider-home',
    dots: true,
    arrows: true,
    swipe: true,
    swipeToSlide: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          centerMode: true,
          variableWidth: process.env.GATSBY_LOCALE !== 'hr',
        },
      },
    ],
  };

  return (
    <div className="section-customers academy-section-customers mb-5">
      <Text as="h2" bold center>
        <Localize id="academy.testimonial.Title" />
      </Text>
      <Slider {...settings}>
        <Card size="large">
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: messages.Review.stars,
            }}
          />
          <Text as="p">
            <Localize id="academy.testimonial.Description1" />
          </Text>
          <Text as="p" className="mb-0">
            <Localize id="academy.testimonial.Name1" />
          </Text>
        </Card>

        <>
          <Card size="large">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: messages.Review.stars,
              }}
            />
            <Text as="p">
              <Localize id="academy.testimonial.Description2" />
            </Text>
          </Card>
          <div className="mt-2" />
          <Card size="large">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: messages.Review.stars,
              }}
            />
            <Text as="p">
              <Localize id="academy.testimonial.Description3" />
            </Text>
          </Card>
          <div className="mt-2" />
          <Card size="large">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: messages.Review.stars,
              }}
            />
            <Text as="p">
              <Localize id="academy.testimonial.Description4" />
            </Text>
          </Card>
        </>

        <>
          <Card size="large">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: messages.Review.stars,
              }}
            />
            <Text as="p">
              <Localize id="academy.testimonial.Description5" />
            </Text>
          </Card>
          <div className="mt-2" />
          <Card size="large">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: messages.Review.stars,
              }}
            />
            <Text as="p">
              <Localize id="academy.testimonial.Description6" />
            </Text>
          </Card>
        </>
      </Slider>
    </div>
  );
};

export default memo(AcademyReviews);
