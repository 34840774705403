import React, { memo } from 'react';
import { Link } from 'gatsby';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text } from '../../../components';

import routes from '../../../routes';

import './style.scss';

const CouponsPricing = () => {
  return (
    <div>
      <Row
        className="mt-5 mb-5 align-items-center justify-content-center"
        noGutters
      >
        <Col xs={10}>
          <Text as="h2" center bold>
            <Localize id="coupons.Pricing.Title" />
          </Text>
        </Col>
      </Row>
      <Row
        className="mt-5 mb-5 align-items-center justify-content-center"
        noGutters
      >
        <Col xs={10}>
          <Text as="h3">
            <Localize id="coupons.Pricing.Section1.First" />
          </Text>
          <Text as="h3">
            <Localize id="coupons.Pricing.Section1.Second" />
          </Text>
          <Text as="h3">
            <Localize
              id="coupons.Pricing.Section1.Third"
              values={{
                link: (
                  <Link
                    to={routes.PRICING_ANCHOR}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.kokolingo.hr/#cjenik
                  </Link>
                ),
              }}
            />
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export default memo(CouponsPricing);
