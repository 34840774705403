import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text, ButtonNew } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

import './style.scss';

const AcademyInstitutionAdditionalInfo = () => {
  return (
    <div>
      <Row
        className="academy-institution-dark-blue-container pt-5 pb-5 justify-content-center align-items-center"
        noGutters
      >
        <Col className="academy-institution-dark-blue-text" xs={10}>
          <Text as="h2" center bold>
            <Localize id="academyInstitution.AdditionalInfo.Title" />
          </Text>
          <Text as="h3" center>
            <Localize
              id="academyInstitution.AdditionalInfo.Description"
              values={{
                u: (chunks) => <u>{chunks}</u>,
              }}
            />
          </Text>
          <Row className="mt-5 justify-content-center align-items-center">
            <ButtonNew
              variant="primary"
              onClick={appUrls.ACADEMY_INSTITUTION_FORM_URL}
            >
              <Localize id="academyInstitution.SectionHeader.CTA" />{' '}
              {emoji.POINTING_FINGER}
            </ButtonNew>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default memo(AcademyInstitutionAdditionalInfo);
