import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import coupons1 from '../../../images/coupons_image1.png';
import coupons2 from '../../../images/coupons_image2.png';

import { Text, ButtonNew } from '../../../components';

import routes from '../../../routes';
import emoji from '../../../constants/emoji';

import './style.scss';

const CouponsIntro = () => {
  return (
    <div>
      <Row
        className="mt-5 mb-5 align-items-center justify-content-center"
        noGutters
      >
        <Col
          className="mb-5"
          md={{ offset: 0, span: 5 }}
          xs={{ offset: 0, span: 10 }}
        >
          <Text as="h3" bold className="mt-0 mb-3">
            <Localize id="coupons.Intro.Section1.Title" />
          </Text>
          <Text>
            <Localize id="coupons.Intro.Section1.Description" />
          </Text>
          <Text as="h3" bold className="mt-3 mb-3">
            <Localize id="coupons.Intro.Section2.Title" />
          </Text>
          <Text>
            <Localize id="coupons.Intro.Section2.Description" />
          </Text>
        </Col>
        <Col
          className="hero-height"
          md={{ offset: 1, span: 4 }}
          xs={{ offset: 0, span: 9 }}
        >
          <picture className="hero-circle hero-circle-tertiary">
            <img src={coupons1} alt="" className="mt-4 mt-sm-0" />
          </picture>
        </Col>
      </Row>
      <Row
        className="coupons-action flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <Col xs={10}>
          <div className="mt-5">
            <Text as="h3" bold center>
              <Localize id="coupons.Intro.Action.First" />
            </Text>
          </div>
          <div>
            <Text as="h3" center>
              <Localize id="coupons.Intro.Action.Second" />
            </Text>
          </div>
          <Row className="mt-5 mb-5 justify-content-center align-items-center">
            <ButtonNew variant="academy" onClick={routes.COUPONS_FORM_ANCHOR}>
              <Localize id="coupons.SectionHeader.CTA" />{' '}
              {emoji.POINTING_FINGER}
            </ButtonNew>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 mb-5 justify-content-center" noGutters>
        <Col className="mr-5" md={{ offset: 0, span: 4 }}>
          <picture className="hero-circle d-none d-md-block">
            <img src={coupons2} alt="" />
          </picture>
        </Col>
        <Col md={{ offset: 0, span: 6 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-0 mb-3">
            <Localize id="coupons.Intro.Section3.Title" />
          </Text>
          <Text>
            <Localize id="coupons.Intro.Section3.Description" />
          </Text>
          <Text as="h3" bold className="mt-3 mb-3">
            <Localize id="coupons.Intro.Section4.Title" />
          </Text>
          <Text>
            <Localize id="coupons.Intro.Section4.Description" />
          </Text>
        </Col>
      </Row>
      <Row
        className="coupons-action flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <Col xs={10}>
          <div className="mt-5">
            <Text as="h3" center>
              <Localize id="coupons.Intro.Action2.First" />
            </Text>
          </div>
          <Row className="mt-5 mb-5 justify-content-center align-items-center">
            <ButtonNew variant="academy" onClick={routes.COUPONS_FORM_ANCHOR}>
              <Localize id="coupons.SectionHeader.CTA" />{' '}
              {emoji.POINTING_FINGER}
            </ButtonNew>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default memo(CouponsIntro);
