import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Tag, Sounds } from '../../components';

const sounds = () => {
  return (
    <Sounds>
      <Row>
        <Col>
          {['K', 'G', 'L', 'Lj', 'R', 'S', 'Z', 'C', 'Š', 'Ž', 'Č'].map(
            (sound) => (
              <Tag key={sound}>{sound}</Tag>
            )
          )}
        </Col>
      </Row>
    </Sounds>
  );
};

export default sounds;
