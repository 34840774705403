import React, { memo } from 'react';
import Slider from 'react-slick';
import { Localize, useIntlMessages } from '@kokolingo/localisation';

import { Text, Card } from '../../../components';

import './style.scss';

const AcademyInstitutionReviews = () => {
  const messages = useIntlMessages();

  const settings = {
    slidesToShow: 1,
    infinite: true,
    className: 'slick-slider-home',
    dots: true,
    arrows: true,
    swipe: true,
    swipeToSlide: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          centerMode: true,
          variableWidth: process.env.GATSBY_LOCALE !== 'hr',
        },
      },
    ],
  };

  return (
    <div className="section-customers academy-institution-section-customers mt-5">
      <Text as="h2" bold center>
        <Localize id="academyInstitution.testimonial.Title" />
      </Text>
      <Slider {...settings}>
        {['1', '2', '3'].map((key) => (
          <Card size="large" key={key}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: messages.Review.stars,
              }}
            />
            <Text as="p">
              <Localize
                id={`academyInstitution.testimonial.Description${key}`}
              />
            </Text>
            <Text as="p" className="mb-0">
              <Localize id={`academyInstitution.testimonial.Name${key}`} />
            </Text>
          </Card>
        ))}
      </Slider>
    </div>
  );
};

export default memo(AcademyInstitutionReviews);
