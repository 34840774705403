const INSTITUTIONS = [
  'Osnovna škola pri Poliklinici za rehabilitaciju slušanja i govora SUVAG',
  'CZOO "Slava Raškaj" Zagreb',
  'CZOO "Velika Gorica"',
  'Poliklinika SUVAG Karlovac',
  'KBC Osijek, Klinika za pedijatriju',
  'CZOO djece i mladeži, Karlovac',
  'Centar za rehabilitaciju, Mir, Kaštel Novi',
  'Caritas Zagrebačke nadbiskupije',
  'OB dr. Ivo Pedišić, Sisak',
  'Dnevni boravak djece "Dr.Antun Bogdan", Čakovec',
  'DV Cvrčak, Knin',
  'DV Pahuljica',
  'DND Grada Crikvenice',
  'Udruga Srce',
  'OB Nova Gradiška',
  'DV Neven, Rovinj',
  'DND Grada Opatije',
  'Reci R d.o.o.',
  'DV Žirek',
  'OŠ Voštarnica, Zadar',
  'DV Dubrava, Zagreb',
  'DV Petar Pan, Zagreb',
  'OŠ "Ivan Leko" Proložac i OŠ "Tin Ujević" Krivodol',
  'DV Vladimira Nazora',
  'DV Girice',
  'DV Vis',
  'Logoria',
  'DV Pinokio',
  'OŠ Josip Kozarac, Soljani',
  'DV Limač',
  'Dom zdravlja Čitluk',
  'OŠ Ivana Cankara',
];

export default INSTITUTIONS;
