import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import headerKokoChristmas from '../../../images/new-landing/header-koko-christmas.png';

import { Text, ButtonNew } from '../../../components';
import routes from '../../../routes';
import emoji from '../../../constants/emoji';

import './style.scss';

const CouponsHeader = () => {
  return (
    <Row
      className="section-header section-header-small coupons-header"
      noGutters
    >
      <Col className="section-header-title" md={{ offset: 1, span: 5 }} sm={12}>
        <img src={headerKokoChristmas} alt="Koko" />
        <Text as="h1" bold>
          <Localize id="coupons.SectionHeader.Title" />
        </Text>
        <Text as="h2">
          <Localize id="coupons.SectionHeader.Subtitle" />
        </Text>
        <div className="mt-5 mb-5">
          <ButtonNew variant="academy" onClick={routes.COUPONS_FORM_ANCHOR}>
            <Localize id="coupons.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Col>
    </Row>
  );
};

export default memo(CouponsHeader);
