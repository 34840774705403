import React from 'react';
import HubspotForm from 'react-hubspot-form';

export default () => (
  <HubspotForm
    portalId="8366494"
    formId="c928cced-46cf-478a-861a-5965fb31f9e6"
    locale="hr"
    region="na1"
  />
);
