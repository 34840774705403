import React from 'react';
import { Localize } from '@kokolingo/localisation';

export default [
  {
    question: <Localize id="FAQassessment.Question1" />,
    answer: <Localize id="FAQassessment.Answer1" />,
  },
  {
    question: <Localize id="FAQassessment.Question2" />,
    answer: <Localize id="FAQassessment.Answer2" />,
  },
  {
    question: <Localize id="FAQassessment.Question3" />,
    answer: <Localize id="FAQassessment.Answer3" />,
  },
  {
    question: <Localize id="FAQassessment.Question4" />,
    answer: <Localize id="FAQassessment.Answer4" />,
  },
  {
    question: <Localize id="FAQassessment.Question5" />,
    answer: <Localize id="FAQassessment.Answer5" />,
  },
];
