import React from 'react';
import { Link } from 'gatsby';

import routes from '../../routes';

export default [
  <Link to={routes.SPEECH_DISORDER} hrefLang="hr">
    Artikulacijski poremećaji
  </Link>,

  <Link to={routes.LANGUAGE_DEVELOPMENT} hrefLang="hr">
    Razvoj jezika i govora
  </Link>,

  <Link to={routes.SPEECH_DEVELOPMENT} hrefLang="hr">
    Razvoj glasova
  </Link>,

  <Link to={routes.MOTOR_AND_HEARING_PREDICTIONS} hrefLang="hr">
    Motoričke i slušne predvježbe
  </Link>,

  <Link to={routes.ENVIRONMENTAL_IMPACT_ON_SPEECH} hrefLang="hr">
    Utjecaj okoline na jezično-govorni razvoj djeteta
  </Link>,

  <Link to={routes.DISTANCE_SPEECH_THERAPY} hrefLang="hr">
    Logopedska terapija na daljinu
  </Link>,

  <Link to={routes.ENCOURAGING_SPEECH_EXPRESSIONS} hrefLang="hr">
    Poticanje jezično-govornog izražavanja u obiteljskom okružju
  </Link>,

  <Link to={routes.ENCOURAGING_LANGUAGE_UNDERSTANDING} hrefLang="hr">
    Poticanje jezičnog razumijevanja i izražavanja
  </Link>,

  <Link to={routes.ENCOURAGING_CORRECT_PRONUNCIATION} hrefLang="hr">
    Poticanje pravilnog izgovora riječI i glasova
  </Link>,

  <Link to={routes.EARLY_INTERVENTION_SPEECH_DEVELOPMENT} hrefLang="hr">
    Rana intervencija i jezično-govorni razvoj
  </Link>,

  <Link to={routes.ONLINE_SPEECH_THERAPY_EXERCISES} hrefLang="hr">
    Online logopedske vježbe
  </Link>,

  <Link to={routes.IMPORTANCE_OF_SPEECH_THERAPY_AT_HOME} hrefLang="hr">
    Važnost provođenja logopedskih vježbi kod kuće
  </Link>,

  <Link to={routes.GAME_AS_A_KEY_TO_CHILDS_GROWTH} hrefLang="hr">
    Igra - ključ rasta i razvoja djece
  </Link>,

  <Link to={routes.PREREQUISITES_OF_READING_AND_WRITING} hrefLang="hr">
    Predvještine čitanja i pisanja
  </Link>,

  <Link to={routes.READING_AND_WRITING} hrefLang="hr">
    Čitanje i pisanje
  </Link>,

  <Link to={routes.BILINGUALISM_AT_CHILDREN} hrefLang="hr">
    Dvojezičnost djece
  </Link>,

  <Link to={routes.AUTISM_SPECTRUM_DISORDER} hrefLang="hr">
    Poremećaj iz spektra autizma
  </Link>,

  <Link to={routes.LIQUID_AND_NON_LIQUID_SPEECH} hrefLang="hr">
    Mucanje i razvojne netečnosti u govoru
  </Link>,

  <Link to={routes.COGNITIVE_DEVELOPMENT_OF_THE_GAME} hrefLang="hr">
    Spoznajni razvoj igre
  </Link>,

  <Link to={routes.PRACTICE_VOICE_R} hrefLang="hr">
    Kako uvježbati glas R
  </Link>,

  <Link to={routes.PRACTICE_VOICE_Š} hrefLang="hr">
    Kokolingo i glas Š
  </Link>,

  <Link to={routes.SPEECH_THERAPISTS_AND_KOKOLINGO} hrefLang="hr">
    Zašto logopedi vole Kokolingo?
  </Link>,

  <Link to={routes.CHILDREN_MOTIVATION_FOR_KOKOLINGO} hrefLang="hr">
    Kako motivirati djecu da ustraju u logopedskim vježbama?
  </Link>,

  <Link to={routes.CHILDHOOD_APRAXIA_OF_SPEECH} hrefLang="hr">
    Dječja govorna apraksija
  </Link>,

  <Link to={routes.BIG_ISSUE_YOU_HAVENT_HEARD_ABOUT} hrefLang="hr">
    Veliki problem za koji sigurno niste čuli
  </Link>,

  <Link to={routes.ENABLE_GREAT_SCHOOL_START} hrefLang="hr">
    Omogućite svome djetetu dobar start u školi
  </Link>,

  <Link to={routes.NO_THEORY_JUST_PRAXIS} hrefLang="hr">
    Ovoga puta bez teorije, samo praksa
  </Link>,

  <Link to={routes.SPEECH_DISORDER_THERAPY} hrefLang="hr">
    Logopedska terapija
  </Link>,

  <Link to={routes.KOKOLINGO_IN_OSIJEK} hrefLang="hr">
    Kokolingo u Osijeku
  </Link>,

  <Link to={routes.VOICES_K_AND_G} hrefLang="hr">
    Izgovor i terapija glasova K i G
  </Link>,

  <Link to={routes.TELETHERAPY_IN_EARLY_INTERVENTION} hrefLang="hr">
    Teleterapija kod rane intervencije
  </Link>,

  <Link to={routes.KOKOLINGO_FOR_MOBILES} hrefLang="hr">
    Kako je nastao Kokolingo za mobitele?
  </Link>,

  <Link to={routes.HOW_TO_FIND_TIME_FOR_KIDS} hrefLang="hr">
    Kako pronaći vremena za vježbanje s djecom?
  </Link>,

  <Link to={routes.DYSLALIA} hrefLang="hr">
    Dislalija
  </Link>,
];
