import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text } from '../../../components';

import academy3 from '../../../images/academy_image3.png';

import './style.scss';

const AcademyAdditionalInfo = () => {
  return (
    <div>
      <Row
        className="mt-5 mb-5 align-items-center justify-content-center"
        noGutters
      >
        <Col md={{ offset: 0, span: 6 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-0 mb-3">
            <Localize id="academy.Pricing.Section1.Title" />
          </Text>
          <Text>
            <Localize id="academy.Pricing.Section1.Description" />
          </Text>
          <Text as="h3" bold className="mt-3 mb-3">
            <Localize id="academy.Pricing.Section2.Title" />
          </Text>
          <Text>
            <Localize id="academy.Pricing.Section2.Description" />
          </Text>
        </Col>
        <Col
          className="hero-height"
          md={{ offset: 1, span: 4 }}
          xs={{ offset: 0, span: 9 }}
        >
          <picture className="hero-circle hero-circle-tertiary">
            <img src={academy3} alt="" className="mt-4 mt-sm-0" />
          </picture>
        </Col>
      </Row>
    </div>
  );
};

export default memo(AcademyAdditionalInfo);
