import * as React from 'react';
import { useIntlMessages } from '@kokolingo/localisation';

import {
  PageNew,
  CouponsHeader,
  CouponsIntro,
  CouponsPricing,
  CouponsForm,
} from '..';

export default () => {
  const messages = useIntlMessages();

  return (
    <PageNew
      title={messages.coupons}
      description=""
      headline=""
      wrapper={(props) => <div {...props} />}
    >
      <CouponsHeader />
      <CouponsIntro />
      <CouponsPricing />
      <CouponsForm />
    </PageNew>
  );
};
