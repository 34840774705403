import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import hero1 from '../../../images/teletherapy_image1.jpg';
import hero2 from '../../../images/teletherapy_image2.jpg';

import { Card, Text, YouTubeVideo } from '../../../components';

import './style.scss';

const TeletherapyIntro = () => {
  return (
    <div className="teletherapy-intro">
      <Row className="justify-content-center align-items-center" noGutters>
        <Col md={{ offset: 0, span: 4 }}>
          <picture className="hero-circle d-none d-md-block">
            <img src={hero1} alt="" />
          </picture>
        </Col>
        <Col md={{ offset: 1, span: 4 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-4 mt-md-0 mb-3">
            <Localize id="teletherapy.SectionIntro.Title1" />
          </Text>
          <Text>
            <Localize
              id="teletherapy.SectionIntro.Description1"
              values={{
                a: (text) => (
                  <a
                    className="font-weight-bold"
                    href="https://www.bib.irb.hr/1139419"
                  >
                    {text}
                  </a>
                ),
              }}
            />
          </Text>
        </Col>
      </Row>
      <Row
        className="short-about-cards-container justify-content-center align-items-center"
        noGutters
      >
        <Col xs={10}>
          <Row className="mt-5 ml-auto mr-auto mb-auto short-about-cards">
            {[...Array(3).keys()].map((i) => (
              <Col xs={12} sm={6} md={4}>
                <Card className="d-flex">
                  <Text as="h3">
                    <Localize
                      id={`teletherapy.SectionIntro.CardTitle${i + 1}`}
                    />
                  </Text>
                  <Text>
                    <Localize
                      id={`teletherapy.SectionIntro.CardDescription${i + 1}`}
                    />
                  </Text>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 justify-content-center align-items-center" noGutters>
        <Col xs={10}>
          <div className="teletherapy-video">
            <YouTubeVideo videoId="EWLoqVzMAEs" />
          </div>
        </Col>
      </Row>
      <Row className="mt-5 align-items-center justify-content-center" noGutters>
        <Col md={{ offset: 0, span: 5 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-0 mb-3">
            <Localize id="teletherapy.SectionIntro.Title2" />
          </Text>
          <Text>
            <Localize id="teletherapy.SectionIntro.Description2" />
          </Text>
        </Col>
        <Col
          className="hero-height"
          md={{ offset: 1, span: 4 }}
          xs={{ offset: 0, span: 9 }}
        >
          <picture className="hero-circle hero-circle-tertiary">
            <img src={hero2} alt="" className="mt-4 mt-sm-0" />
          </picture>
        </Col>
      </Row>
    </div>
  );
};

export default memo(TeletherapyIntro);
