import React, { memo, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Form, Spinner } from 'react-bootstrap';
import { Localize, useIntlMessages } from '@kokolingo/localisation';

import routes from '../../routes';
import appUrls from '../../constants/appUrls';

import {
  ButtonNew,
  Input,
  Modal,
  ModalContext,
  Text,
  Tooltip,
} from '../../components';

import modalTypes from '../../constants/modalTypes';

const ModalRegister = ({ subscriptionKey, showPackageLabel }) => {
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState('');
  const [errors, setErrors] = useState(false);
  const { modalData, setModalData } = useContext(ModalContext);

  const close = useCallback(() => {
    setModalData({ type: modalTypes.NONE, props: {} });
  }, [setModalData]);

  const messages = useIntlMessages();

  const handleInputChange = (event) => {
    setFormState(event.target.value);
  };

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setErrors(!regex.test(email));
    return regex.test(email);
  };

  const resetForm = () => {
    setFormState('');
    document.getElementById('email').value = '';
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateEmail(formState)) {
      return;
    }
    setSubmitting(true);
    const fullName = formState.substring(0, formState.lastIndexOf('@'));
    const studentFullName = `${fullName} - dijete`;

    axios
      .post(appUrls.API_REGISTER_NO_PW, {
        studentFullName,
        fullName,
        email: formState,
        gdpr: true,
      })
      .then((response) => {
        if (response.status === 201) {
          window.location.href = `${appUrls.APP_LOGIN}?token=${response.data.token}&redirect=${appUrls.APP_PAYMENT}?subscriptionKey=${subscriptionKey}`;
        }
      })
      .catch((error) => {
        if (error.response?.data?.message === 'error.feedback.emailInUse') {
          window.location.href = `${appUrls.APP_LOGIN}?redirect=${appUrls.APP_PAYMENT}?subscriptionKey=${subscriptionKey}`;
        } else if (
          error.response?.data?.message ===
          'error.feedback.subscriptionIsActive'
        ) {
          window.location.href = `${appUrls.APP_LOGIN}?redirect=${appUrls.APP_SUBSCRIPTION_STATUS}`;
        } else if (!error.response) {
          alert(messages.homePage.RegisterError.Server);
        } else {
          alert(messages.homePage.RegisterError.Unknown);
        }
        setSubmitting(false);
        resetForm();
      });
  };

  return (
    <Modal
      show={modalData.type === modalTypes.REGISTER}
      onClose={close}
      className="height-unset"
    >
      <Modal.Description>
        {showPackageLabel && (
          <Text as="h3" className="my-3">
            <Localize id="homePage.RegisterForm.PackageLabelTitle" />{' '}
            {subscriptionKey}
          </Text>
        )}
        <Text as="h3" className="my-3">
          <Localize id="homePage.RegisterForm.Title" />
        </Text>
        <Form onSubmit={handleSubmit}>
          <Tooltip
            placement="top"
            show={errors}
            tooltipMessage={messages.homePage.RegisterFormError.Email}
          >
            <Form.Group className="mb-3 input-group">
              <Input
                id="email"
                type="email"
                className="form-control"
                placeholder={messages.input.email}
                onChange={handleInputChange}
                error={errors}
              />
              <Input.Append>
                {submitting ? (
                  <Spinner
                    as="span"
                    className="mr-1"
                    animation="border"
                    role="status"
                  />
                ) : (
                  <ButtonNew type="submit" size="small">
                    <Localize id="homePage.SectionFooter.Next" />
                  </ButtonNew>
                )}
              </Input.Append>
            </Form.Group>
          </Tooltip>
        </Form>
        <Text as="p" size="small" className="mb-0">
          <Localize
            id="homePage.SectionFooter.termsOfService"
            values={{
              termsOfService: (linkText) => (
                <a href={routes.TOS} target="_blank" rel="noreferrer">
                  {linkText}
                </a>
              ),
              privacyPolicy: (linkText) => (
                <a
                  href={routes.PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer"
                >
                  {linkText}
                </a>
              ),
            }}
          />
        </Text>
      </Modal.Description>
    </Modal>
  );
};

ModalRegister.propTypes = {
  subscriptionKey: PropTypes.string.isRequired,
  showPackageLabel: PropTypes.bool,
};

ModalRegister.defaultProps = {
  showPackageLabel: false,
};

export default memo(ModalRegister);
