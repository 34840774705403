import React from 'react';
import HubspotForm from 'react-hubspot-form';

export default () => (
  <HubspotForm
    portalId="8366494"
    formId="2de79109-95d5-4afa-8b28-da187db59275"
    locale="hr"
    region="na1"
  />
);
