import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { Localize } from '@kokolingo/localisation';

import { Text, YouTubeVideo } from '../../../components';

const requireAll = (reqCtx) => reqCtx.keys().map(reqCtx);

const MEDIA_LOGOS = requireAll(
  require.context('../../../images/hr/media-logos', false, /\.png$/)
);

const SectionMedia = () => {
  return (
    <Row className="section-media" noGutters>
      <Col xs={{ offset: 1, span: 10 }}>
        <Text as="h2" bold center>
          <Localize id="homePage.SectionMedia.Title" />
        </Text>
      </Col>
      <Col xs={{ offset: 1, span: 10 }}>
        <Row className="media-logos justify-content-center">
          {MEDIA_LOGOS.map((logo) => {
            const logoName = logo.split('-')[1];
            return (
              <LazyLoad classNamePrefix="media-logos-item" offset={100} once>
                <img
                  src={logo}
                  alt={logoName}
                  key={logoName}
                  height="40"
                  className="m-1"
                />
              </LazyLoad>
            );
          })}
        </Row>

        <Row className="media-videos">
          <Col
            className="media-video-container text-center"
            lg={{ offset: 0, span: 4 }}
            sm={{ offset: 3, span: 6 }}
            xs={{ offset: 1, span: 10 }}
          >
            <LazyLoad classNamePrefix="media-video-container" offset={100} once>
              <YouTubeVideo videoId="y8_c1RBxGXM" />
            </LazyLoad>
          </Col>
          <Col
            className="media-video-container text-center"
            lg={{ offset: 0, span: 4 }}
            sm={{ offset: 0, span: 6 }}
            xs={{ offset: 1, span: 10 }}
          >
            <LazyLoad classNamePrefix="media-video-container" offset={100} once>
              <YouTubeVideo videoId="AswWQsw09jo" />
            </LazyLoad>
          </Col>
          <Col
            className="media-video-container text-center"
            lg={{ offset: 0, span: 4 }}
            sm={{ offset: 0, span: 6 }}
            xs={{ offset: 1, span: 10 }}
          >
            <LazyLoad classNamePrefix="media-video-container" offset={100} once>
              <YouTubeVideo videoId="aisyGqidLsE" />
            </LazyLoad>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default memo(SectionMedia);
