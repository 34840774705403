import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text } from '../../../components';

import './style.scss';

const AcademyInstitutionPricing = () => {
  return (
    <Row
      className="academy-institution-pricing justify-content-center align-items-center"
      noGutters
    >
      <Col xs={10}>
        <Row className="flex-column" noGutters>
          <Row
            className="academy-institution-pricing-section flex-column pl-3 pr-3 justify-content-center align-items-center"
            noGutters
          >
            <div className="mt-5">
              <Text as="h3" bold>
                <Localize id="academyInstitution.Pricing.DescriptionTitle" />
              </Text>
            </div>
            <div className="mt-5">
              <Text as="h3">
                <Localize
                  id="academyInstitution.Pricing.Description"
                  values={{
                    ol: (list) => <ol>{list}</ol>,
                    li: (listElement) => <li>{listElement}</li>,
                  }}
                />
              </Text>
            </div>
          </Row>
          <Row
            className="academy-institution-action flex-column pl-3 pr-3 justify-content-center align-items-center"
            noGutters
          >
            <div className="academy-institution-academy-text mt-5 mb-5">
              <Text as="h2" bold center>
                <Localize id="academyInstitution.Pricing.Price" />
              </Text>
            </div>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default memo(AcademyInstitutionPricing);
