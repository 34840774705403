import * as React from 'react';

import { Text, BlogImage, BlogLiterature, BlogLink } from '../../components';

import { PageNew, SectionBlog } from '..';

import speechDisorder from '../../images/educational-corner/blog1/Fonoloski-poremecaj.png';

const SpeechDisorderPage = () => {
  return (
    <PageNew
      title="KOKOLINGO - Artikulacijsko – fonološki poremećaji"
      description="Kokolingo pomaže djeci kod artikulacijskih i fonoloških poremećaja. 
      Zbog nepravilnog izgovora glasova roditelji dovode djecu na logopedske preglede."
      headline="Što su to artikulacijsko – fonološki poremećaji?"
    >
      <Text as="p">
        <b>Nepravilan izgovor glasova jedan je od najčešćih problema </b>
        zbog kojeg roditelji danas dovode djecu na logopedske preglede. Neki
        roditelji ovakve teškoće prepoznaju sami, no mnogi nisu sigurni u kojem
        trenutku njihovo dijete treba „moći izgovoriti“ određeni glas.
      </Text>
      <Text as="p">
        Već je dugo poznato kako je izgovor nekih glasova djeci jednostavno teži
        pa se tako nerijetko može čuti da mnogo djece ide kod logopeda zbog
        glasa „R“ ili zato što dijete „šuška“ dok govori. Roditelji tada, često
        zbunjeni, pokušavaju otkriti što je točno razlog tome što dijete neki
        glas ne može usvojiti, a mnogi njegovi vršnjaci bez poteškoća
        izgovaraju. Kada se nađu u ovoj situaciji, dakako,
        <b>
          {' '}
          roditelji se trebaju javiti logopedu koji će napraviti detaljnu
          dijagnostiku komunikacijskog i jezično-govornog razvoja djeteta.{' '}
        </b>
        Nakon toga slijedi razgovor logopeda s roditeljima o daljnjim koracima
        koje treba poduzeti.
      </Text>
      <Text as="p">
        Nakon što logoped otkrije specifičnu nepravilnost u izgovoru, moguće je
        da će roditelji čuti kako se kod djeteta radi o nečemu što se zove
        artikulacijski poremećaj, odnosno fonološki poremećaj. Budući da su ti
        poremećaji vrlo često udruženi, može se govoriti o
        artikulacijsko-fonološkom poremećaju.
      </Text>
      <Text as="h2">
        Razlika između artikulacijskih i fonoloških poremećaja
      </Text>
      <Text as="p">
        Ipak, ova je dva poremećaja bitno razlikovati jer o njima uvelike ovisi
        daljnji tijek logopedske terapije i vježbi koje će roditeljima biti dane
        za rad kod kuće.
        <b>
          {' '}
          Artikulacijski poremećaj označava „čisti“ poremećaj izgovora glasova
          koji najčešće nastaje zbog pogrešnog pozicioniranja artikulatora
          (govornih organa) tijekom izgovora glasa.{' '}
        </b>
        U pozadini artikulacijskog poremećaja mogu ponekad biti organski uzroci,
        odnosno odstupanja u građi i funkciji određenih govornih organa. Zbog
        toga na inicijalnom logopedskom pregledu treba utvrditi je li izgovorna
        teškoća uvjetovana nekim anatomskim nedostatkom.
      </Text>
      <Text as="p">
        <b>
          {' '}
          Kod artikulacijskog poremećaja djeca su najčešće razumljiva okolini
          jer je izgovor glasova sustavno narušen,{' '}
        </b>
        npr. dijete će u izgovoru sustavno glas Š zamjenjivati glasom S pa će
        tako „ŠUNKA“ biti „SUNKA“, „KOŠARA“ će biti „KOSARA“ te će „MIŠ“ biti
        „MIS“.
      </Text>
      <Text as="p">
        Za razliku od ovoga,
        <b>
          {' '}
          kod fonološkog poremećaja izgovor glasova u djece nije sustavan,{' '}
        </b>
        odnosno dijete jedan glas može različito izgovarati ovisno o njegovoj
        poziciji u riječi i glasovima koji ga okružuju. Tako će primjerice,
        dijete reći kako bi „jelo UNKU“, kako je „maca bila u KOSARI“ te kako
        „MIŠ jede sir“. Ovo jasno pokazuje kako
        <b>
          {' '}
          dijete može motorički izvesti glas, no percepcija samog glasa u
          različitim riječima nije sustavna.
        </b>
      </Text>
      <BlogImage
        imgSrc={speechDisorder}
        imgAlt="Fonoloski poremecaj"
        imgTitle="Fonoloski poremecaj pri izgovaranju"
      />
      <Text as="h2">Važnost suradnje između roditelja i logopeda</Text>
      <Text as="p">
        Vrste pogrešaka koje se događaju u izgovoru logoped će pažljivo uočiti
        te na temelju toga zaključiti je li riječ o čistom artikulacijskom
        poremećaju ili je u pitanju i to da dijete nema stabilne mentalne
        reprezentacije glasova te posljedično ima i fonološki poremećaj. Oba
        poremećaja iziskuju stručno vođenje logopeda pri odabiru vježbi koje je
        potrebno s djetetom provoditi kod kuće. Iznimno je bitno naglasiti
        veliku ulogu koju roditelji imaju pri korekciji izgovora glasova.
        <b>
          {' '}
          Bez suradnje roditelja i logopeda te bez kontinuiranog provođenja
          vježbi kod kuće prema uputama rezultati mogu izostati ili se sam
          proces učenja pravilnog izgovora glasova može oduljiti,{' '}
        </b>
        što onda dodatno frustrirajuće djeluje na roditelja i na samo dijete.
      </Text>
      <Text as="p">
        S ciljem kontinuirane vježbe, kako bi djeca što prije postigla željene
        rezultate, nastala je i prva digitalna vježbenica za djecu s
        artikulacijskim poremećajima -<BlogLink> Kokolingo. </BlogLink>
        Ona može biti od velike pomoći u procesu korekcije izgovora glasova kako
        roditeljima tako i logopedima.
      </Text>
      <Text as="p">Patricia Orlić, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Kuvač Kraljević, J., ur. (2015). Priručnik za prepoznavanje i
            obrazovanje djece s jezičnim teškoćama. Zagreb:
            Edukacijsko-rehabilitacijski fakultet Sveučilišta u Zagrebu
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default SpeechDisorderPage;
