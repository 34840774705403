import React, { memo } from 'react';
import { Col, Row, ButtonGroup } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import academy1 from '../../../images/academy_image1.png';
import academy2 from '../../../images/academy_image2.png';
import academyVideoThumbnail from '../../../images/academy_video_thumbnail.jpg';

import { Text, ButtonNew, YouTubeVideo } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';
import routes from '../../../routes';

import './style.scss';

const AcademyIntro = () => {
  return (
    <div className="academy-intro">
      <Row className="mt-5 justify-content-center align-items-center" noGutters>
        <Col xs={10}>
          <div className="academy-intro-buttons-container mt-5 mb-5">
            <ButtonGroup>
              <ButtonNew variant="academy">
                <Localize id="pricing.ParentsTitle" />
              </ButtonNew>
              <ButtonNew
                className="button-new__light"
                variant="primary"
                onClick={routes.ACADEMY_INSTITUTION}
              >
                <Localize id="pricing.FacilitiesTitle" />
              </ButtonNew>
            </ButtonGroup>
          </div>
        </Col>
        <Col xs={10}>
          <div className="academy-video">
            <YouTubeVideo
              videoId="w794RizrwJA"
              videoImgUrl={academyVideoThumbnail}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-5 align-items-center justify-content-center" noGutters>
        <Col md={{ offset: 0, span: 5 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-0 mb-3">
            <Localize id="academy.SectionIntro.Title2" />
          </Text>
          <Text>
            <Localize
              id="academy.SectionIntro.Description2"
              values={{
                ul: (list) => <ul>{list}</ul>,
                li: (listElement) => <li>{listElement}</li>,
              }}
            />
          </Text>
        </Col>
        <Col
          className="hero-height"
          md={{ offset: 1, span: 4 }}
          xs={{ offset: 0, span: 9 }}
        >
          <picture className="hero-circle hero-circle-tertiary">
            <img src={academy1} alt="" className="mt-4 mt-sm-0" />
          </picture>
        </Col>
      </Row>
      <Row
        className="academy-action flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <div className="mt-5">
          <Text as="h3" bold center>
            <Localize id="academy.SectionIntro.Action.First" />
          </Text>
        </div>
        <div>
          <Text as="h3" bold center>
            <Localize id="academy.SectionIntro.Action.Second" />
          </Text>
        </div>
        <div className="mt-3 mb-5">
          <ButtonNew variant="academy" onClick={appUrls.ACADEMY_FORM_URL}>
            <Localize id="academy.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Row>
      <Row
        className="mt-5 mb-5 justify-content-center align-items-center"
        noGutters
      >
        <Col md={{ offset: 0, span: 5 }}>
          <picture className="hero-circle d-none d-md-block">
            <img src={academy2} alt="" />
          </picture>
        </Col>
        <Col md={{ offset: 0, span: 5 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-4 mt-md-0 mb-3">
            <Localize id="academy.SectionIntro.Title3" />
          </Text>
          <Text>
            <Localize
              id="academy.SectionIntro.Description3"
              values={{
                ul: (list) => <ul>{list}</ul>,
                li: (listElement) => <li>{listElement}</li>,
              }}
            />
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export default memo(AcademyIntro);
