import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import headerKoko from '../../../images/new-landing/header-koko.svg';
import headerKokoMin from '../../../images/new-landing/header-koko-min.png';
import headerHero from '../../../images/hr/header-hero.png';
import headerHeroMin from '../../../images/hr/header-hero-min.png';
import reviews from '../../../images/hr/reviews.png';

import appUrls from '../../../constants/appUrls';
import routes from '../../../routes';
import emoji from '../../../constants/emoji';

import { Text, ButtonNew, ProgressiveImage } from '../../../components';

import './index.scss';

import reviewer1 from '../../../images/default/testimonials/1-min.jpg';
import reviewer2 from '../../../images/default/testimonials/5-min.jpg';
import reviewer3 from '../../../images/default/testimonials/6-min.jpg';
import reviewer4 from '../../../images/default/testimonials/7-min.jpg';

const reviewers = [reviewer1, reviewer2, reviewer3, reviewer4];

const Header = () => {
  return (
    <Row className="section-header main-header" noGutters>
      <Col
        className="section-header-title"
        md={{ offset: 1, span: 5 }}
        sm={{ offset: 1, span: 10 }}
      >
        <ProgressiveImage
          src={headerKoko}
          placeholderSrc={headerKokoMin}
          alt="Koko"
        />
        <Text as="h1" bold>
          <Localize id="homePage.SectionHeader.Title" />
        </Text>
        <Text as="h3" className="small-margin">
          <Localize id="homePage.SectionHeader.Description1" />
        </Text>
        <ButtonNew onClick={appUrls.HOME}>
          <Localize id="homePage.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
        </ButtonNew>
        <Text as="h3" />
        <Text as="h3" className="small-margin">
          <Localize id="homePage.SectionHeader.Description2" />
        </Text>
        <ButtonNew onClick={routes.TELETHERAPY}>
          <Localize id="homePage.SectionHeader.CTA2" /> {emoji.POINTING_FINGER}
        </ButtonNew>
        <Text as="h3" />
        <Text as="h3" className="small-margin">
          <Localize id="homePage.SectionHeader.Description3" />
        </Text>
        <ButtonNew variant="academy" onClick={routes.ACADEMY}>
          <Localize id="homePage.SectionHeader.CTA3" /> {emoji.POINTING_FINGER}
        </ButtonNew>
      </Col>
      <Col
        className="section-header-hero"
        md={{ offset: 1, span: 4 }}
        sm={{ offset: 1, span: 10 }}
      >
        <ProgressiveImage
          src={headerHero}
          placeholderSrc={headerHeroMin}
          alt=""
        />
        <Row
          className="reviews justify-content-center align-content-center mt-5"
          width="100%"
          md="2"
          sm="5"
        >
          <Col
            md="3"
            sm="8"
            xs="12"
            className="d-flex justify-content-center align-content-center"
            style={{ marginLeft: '20px' }}
          >
            <Row className="nowrap">
              {reviewers.map((reviewer) => (
                <div style={{ marginLeft: '-20px' }}>
                  <img
                    src={reviewer}
                    alt=""
                    style={{
                      borderRadius: '50%',
                      border: 'solid',
                      borderWidth: '3px',
                      borderColor: '#fff',
                      marginTop: '0px',
                    }}
                  />
                </div>
              ))}
            </Row>
          </Col>
          <Col
            xl="3"
            md="5"
            sm="10"
            xs="12"
            className="d-flex flex-wrap justify-content-start align-content-center"
          >
            <img src={reviews} alt="" style={{ margin: '0' }} />
            <a
              href="https://www.google.com/search?q=kokolingo#lrd=0x4764a771252c059f:0x3c2d850bb3e8c1fb,1,,,,"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#32b006',
                fontWeight: 'bold',
                fontSize: '0.7rem',
                marginRight: '-40px',
              }}
            >
              40 Google recenzija
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
