import * as React from 'react';

import {
  Text,
  BlogImage,
  BlogInfographics,
  BlogLiterature,
  BlogLink,
} from '../../components';

import { PageNew, SectionBlog } from '../index';

import infographic from '../../images/educational-corner/blog3/infografika.png';

import speechDevThroughYears from '../../images/educational-corner/blog3/Razvoj-glasova-kroz-godine.png';
import pronunciationSteps from '../../images/educational-corner/blog3/Slijed-korekcije-izgovora-glasova.png';

export default () => {
  return (
    <PageNew
      title="KOKOLINGO - Speech development"
      description="Problems with correct pronunciation of
      particular speech sounds may occur in the development
      of speech. The exercises in the Kokolingo speech
      therapy app are designed to target these problems."
      headline="Razvoj glasova"
    >
      <Text as="p">
        Sposobnost govora jedna je od temeljnih ljudskih vještina. Njime
        razmjenjujemo informacije, prenosimo emocionalna stanja, iskazujemo
        stavove, gradimo i održavamo odnose.
        <b>
          {' '}
          Kako bi nas drugi ljudi razumjeli, naš govor mora biti smislen,
          povezan i jasan.{' '}
        </b>
        Ponekad se može dogoditi da okolina slabije razumije osobe koje imaju
        teškoća u izgovoru određenih glasova. Zbog poremećaja artikulacije
        njihov govor može se činiti manje razgovijetnim te može odvlačiti
        pozornost sugovornika od teme razgovora.
      </Text>
      <Text as="p">
        <b>Glasovi čine osnovu govora, </b>a povezivanjem tvore riječi i
        rečenice. Oni su najmanji dio jezika, a broj glasova varira od jezika do
        jezika. U hrvatskom jeziku imamo ih sveukupno 30.
        <b>
          {' '}
          Oni se postupno pojavljuju u govoru, od jednostavnijih do složenijih,
          tako da ih možemo očekivati u određenim dobnim okvirima.{' '}
        </b>
        Ti dobni okviri nisu fiksni i variraju od djeteta do djeteta. Možemo ih
        shvatiti kao smjernice za praćenje razvoja govora kod djeteta.
      </Text>
      <Text as="p">
        Jednostavnije glasove, ponajviše samoglasnike “A”, “O” i “E” te glasove
        “P” i “B” djeca usvajaju između prve i druge godine. Jednostavan način
        tvorbe ovih glasova, odnosno njihovo lako uočavanje na usnama
        sugovornika dovodi do njihova ranijeg usvajanja. Između druge i treće
        godine djeca ubrzano bogate svoj rječnik, a ujedno i brže usvajaju nove
        glasove. Djeca bi u ovoj dobi trebala usvojiti samoglasnike “I” i “U” te
        suglasnike “F”, “V”, “T”, “D”, “N”, “Nj”, “M”, “K”, “G”, “H” i “J”.
        Između treće i četvrte godine djeca počinju koristiti nešto složenije
        glasove - “S”, “Z”, “C”, “Š”, “Ž”, “L” i “Lj”. Naposljetku, na prijelazu
        s četvrte na petu godinu djeca ovladavaju i glasovima “Č”, “Ć”, “Dž”,
        “Đ” i “R”.
      </Text>
      <BlogImage
        imgSrc={speechDevThroughYears}
        imgAlt="The development of voices over the years"
        imgTitle="Development of voices"
      />
      <Text as="p">
        Svako je dijete individualno te se navedeni glasovi neće nužno razviti u
        tim dobnim rasponima. Međutim,
        <b>
          {' '}
          dijete u dobi od pet i pol ili šest godina trebalo bi imati pravilan
          izgovor svih glasova.{' '}
        </b>
        Bitno je znati do koje dobi možemo tolerirati nepravilan izgovor
        glasova, odnosno kada pogrešku treba početi tretirati kao poremećaj i
        započeti s korekcijom. Tako, primjerice, korekciju glasa “R” nećemo
        započeti ako dijete ima tri i pol godine s obzirom na to da se u toj
        dobi i ne očekuje od njega da pravilno izgovara navedeni glas. Ako
        dijete ima više od pet godina, a još ima nepravilan izgovor glasa “R”,
        tada zasigurno treba započeti korekciju izgovora toga glasa.
      </Text>
      <Text as="p">
        <b>
          Neki od najčešće poremećenih glasova su “S”, “Z” i “C”, “Š”, “Ž” i
          “Č”, “L”, “Lj” i “R” te “K” i “G”.{' '}
        </b>
        Izgovor ovih glasova može biti poremećen izolirano, odnosno
        <b> može biti zahvaćen samo jedan glas ili više njih. </b>
        Ako dijete ima poremećen izgovor više glasova moramo napraviti plan
        korekcije zahvaćenih glasova. Taj plan radi logoped, individualno, prema
        potrebama djeteta. Korekcija izgovora pojedinih glasova teče postupno,
        kao i usvajanje glasova. To znači da će se u terapiji uglavnom provoditi
        vježbe za dobivanje pravilnog izgovora jednoga glasa. U slučaju da
        dijete ima narušen izgovor glasova iz različitih skupina, moguće je
        provoditi vježbe za pravilan izgovor maksimalno dva glasa. Važno je
        naglasiti da se u tom slučaju radi samo s glasovima koji nisu bliski po
        zvučnosti, mjestu i načinu tvorbe. Primjerice, nećemo raditi istodobnu
        korekciju glasova “S” i “Z”, ali možemo istodobno odrađivati vježbe za
        glasove “S” i “G” ili “Z” i “L”. Ako je zahvaćena skupina glasova,
        korekciju je potrebno započeti s onim glasom koji je temelj te skupine,
        primjerice glas “S” u skupini “S”, “Z”, “C” ili glas “Š” u skupini “Š”,
        “Ž”, “Č”. Također, bitno je obratiti pozornost na poredak usvajanja
        glasova i prema tome raditi plan vježbi. Glas “K” dijete bi trebalo
        usvojiti prije glasa “G”. Stoga bi vježbe trebale započeti s glasom “K”.
        Nakon što dijete usvoji taj glas, možemo prijeći na korekciju glasa “G”.
        Isto vrijedi i za glasove “L” i “R”. Prvo treba dobiti pravilan izgovor
        glasa “L”, a tek onda krenuti s vježbama za glas “R”. Kada dijete u
        spontanom govoru počne pravilno izgovarati određeni glas, tada znamo da
        ga je usvojilo te je moguće započeti s korekcijom sljedećega glasa.
      </Text>
      <BlogImage
        imgSrc={pronunciationSteps}
        imgAlt="Sequence of voice pronunciation correction"
        imgTitle="Voice correction sequence"
      />
      <Text as="p">
        Glasovni razvoj teče postupno, od jednostavnijih do složenijih glasova.
        Kako dijete razvija sve veću kontrolu nad svojim govornim aparatom te se
        sve više koristi govorom za prenošenje informacija, tako i ovladava sve
        složenijim glasovima. Naravno, u razvoju može doći do teškoća u izgovoru
        određenih glasova, posebice glasova “K” i “G”, “L”, “Lj” i “R”, “S”, “Z”
        i “C” te glasova “Š”, “Ž” i “Č”. Budući da većina djece ima teškoća u
        izgovoru ovih glasova upravo su za njih osmišljene vježbe u
        <BlogLink> Kokolingo digitalnoj logopedskoj vježbenici. </BlogLink>
        Uz sustavan rad kod kuće i pratnju logopeda dijete će ovladati pravilnim
        izgovorom i postati izvrstan govornik hrvatskog jezika.
      </Text>
      <Text as="p">Krešimir Perša, mag. speech therapist.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Posokhova, I. (2005). <i>Izgovor: Kako ga poboljšati</i>. Lekenik:
            Ostvarenje.
          </li>
        </ul>
      </BlogLiterature>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};
