import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Card, Text } from '../../../components';

import ema from '../images/ema.jpg';
import karla from '../images/karla.jpg';
import sara from '../images/sara.jpg';
import biljana from '../images/biljana.jpg';
import jasmina from '../images/jasmina.jpg';
import ivan from '../images/ivan.jpg';

import './style.scss';

const peopleImages = [ema, karla, sara, biljana, jasmina, ivan];

const TeletherapyGuarantee = () => {
  return (
    <div className="short-about teletherapy-guarantee mt-5">
      <Row noGutters>
        <Col xs={10}>
          <Text as="h2" center bold>
            <Localize id="teletherapy.SectionGuarantee.Title" />
          </Text>
          <Text>
            <Localize id="teletherapy.SectionGuarantee.Subdescription" />
          </Text>
          <Text>
            <Localize id="teletherapy.SectionGuarantee.Description" />
          </Text>

          <Row className="mt-5 short-about-cards short-about-cards-people">
            {[...Array(6).keys()].map((i) => (
              <Col xs={12} sm={6} md={4}>
                <Card className="d-flex">
                  <div>
                    <img
                      loading="lazy"
                      className="short-about-cards-people-image"
                      src={peopleImages[i]}
                      title={`teletherapy.SectionGuarantee.Name${i + 1}`}
                      alt={`teletherapy.SectionGuarantee.Name${i + 1}`}
                    />
                  </div>
                  <Text as="h3" bold>
                    <Localize
                      id={`teletherapy.SectionGuarantee.Name${i + 1}`}
                    />
                  </Text>
                  <Text as="h4" bold>
                    <Localize
                      id={`teletherapy.SectionGuarantee.Title${i + 1}`}
                    />
                  </Text>
                  <Text>
                    <Localize
                      id={`teletherapy.SectionGuarantee.Description${i + 1}`}
                    />
                  </Text>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default memo(TeletherapyGuarantee);
