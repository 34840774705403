import React, { memo } from 'react';
import { Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text, ButtonNew } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

import './style.scss';

const AcademyPricing = () => {
  return (
    <div className="academy-pricing">
      <Row
        className="academy-pricing-section flex-column pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <div className="mt-5">
          <Text as="h2" bold center>
            <Localize
              id="academy.Pricing.Title1"
              values={{
                i: (chunks) => <i>{chunks}</i>,
                s: (chunks) => <s>{chunks}</s>,
              }}
            />
          </Text>
        </div>
      </Row>
      <Row
        className="academy-action flex-column pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <div className="mt-5">
          <Text as="h3" bold center>
            <Localize id="academy.Pricing.Action.First" />
          </Text>
          <Text as="h3" bold center>
            <Localize id="academy.Pricing.Action.Second" />
          </Text>
        </div>
        <div className="mt-3 mb-5">
          <ButtonNew variant="academy" onClick={appUrls.ACADEMY_FORM_URL}>
            <Localize id="academy.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Row>
    </div>
  );
};

export default memo(AcademyPricing);
